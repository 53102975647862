import api from './baseService';

export const getCompanies = (queryStringParams: string = '') =>
	api().get(`/pdb/companies?${queryStringParams}`);

export const getAllCompanies = async (params: Record<string, any>) => {
	const countResponse = await api().get('/pdb/companies', { params: { count: true } });
	const limit = 500;
	const calls = Array.apply(
		null,
		Array.from(Array(Math.ceil(countResponse.data.total / limit))),
	).map((v, index) =>
		api().get('/pdb/companies', { params: { limit, offset: limit * index, ...params } }),
	);

	const results = await Promise.all(calls);
	return { data: results.map((result) => result.data).flat() };
};

export const getCompaniesInBulk = (companyIds: string[], fields: string[] = ['company_name'], token: string) => {
	companyIds = companyIds && [companyIds].flat();
	fields = fields && [fields].flat();

	const params = new URLSearchParams();
	companyIds && companyIds.forEach((v) => params.append('company_id', v));
	fields && fields.forEach((v) => params.append('field', v));
	return api().get('/pdb/companies', {
		params,
		...(token && { headers: { 'X-PDB-Authorization': token } }),
	});
};

interface CompanyProfileData {
	company_information: CompanyProfile;
}
export const getCompanyProfile = (ndid_company_id: string) =>
	api().get<CompanyProfileData>(`/pdb/companies/${ndid_company_id}`);

export const getCompanyAgreements = (ndid_company_id?: string) =>
	api().get<CompanyAgreement[]>(`/pdb/companyagreements?ndid_company_id=${ndid_company_id}`, {
		description: 'a retrieval of active company agreements',
	});

export const setCompanyNote = (companyId: string, note: string) =>
	api().put(`/pdb/companies/${companyId}/note`, {
		note: note,
	});

export const putCompanyProjectability = (companyId: string, companyProjectability: 0 | 1) =>
	api().put(`/pdb/companies/${companyId}/projectioneligibleflag`, {
		companyProjectability: companyProjectability,
	});

export const putSkuRequired = (companyId: string, skuRequired: number) =>
	api().put(`/pdb/companies/${companyId}/partnumberrequired`, {
		skuRequired: skuRequired,
	});

export const setMakerCode = (companyId: string, makerCode: string) =>
	api().put(`/pdb/companies/${companyId}/makercode`, {
		makerCode: makerCode,
	});
