import React, { useRef, useState, VFC } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import ActionLink from '../../components/ActionLink/ActionLink';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FAIcon from '../../components/FAIcon/FAIcon';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal/DeleteConfirmationModal';
import SimpleModal from '../../components/modals/SimpleModal/SimpleModal';
import Page from '../../components/Page/Page';
import StatusText from '../../components/StatusText/StatusText';
import Title from '../../components/Title/Title';
import { useCompanyProfileQuery } from '../../hooks/queryHooks';
import { useUserProfile } from '../../hooks/reduxHooks';
import { deleteDigitalCodeOrder, getOrderSummary, postOrderCancel } from '../../services/digitalCodesService';
import { getPermissions } from '../../utils/digitalCodesUtils';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import Step1OrderAndProducts from './views/Step1OrderAndProducts';
import Step2Delivery from './views/Step2Delivery';
import Step3DigitalCodesOrderConfirmation from './views/Step3DigitalCodesOrderConfirmation';


const STEP_1 = 'step-1-order-and-products' as const;
const STEP_2 = 'step-2-shipping' as const;
const STEP_3 = 'step-3-confirmation' as const;


const DigitalCodesOrderCreate: VFC = () => {
	const userProfile = useUserProfile();
	const { orderHeaderId } = useParams<{ orderHeaderId?: string }>();
	const history = useHistory();
	const [step, setStep] = useState<typeof STEP_1 | typeof STEP_2 | typeof STEP_3 | null>(STEP_1);
	const [openModal, setOpenModal] = useState<{ type?: 'DELETE_MODAL' | 'CANCEL_MODAL' | 'INFO_MODAL' }>({});

	const saveCallRef = useRef<Function | undefined>();

	const companyProfileQuery = useCompanyProfileQuery();
	const companyProfile = companyProfileQuery.data?.data?.company_information;

	const orderSummaryQuery = useQuery(
		['getOrderSummary', orderHeaderId],
		() => getOrderSummary(String(orderHeaderId)),
		{
			enabled: !!orderHeaderId,
			retryOnMount: false,
			refetchOnMount: false,
		},
	);
	const orderData = orderSummaryQuery.data?.data;

	const { canCancelOrder, canDeleteOrder } = getPermissions(userProfile);
	const orderIsCancellable = orderData?.status === 'REJECTED' && canCancelOrder;
	const orderIsDeletable = orderData?.status === 'DRAFT' && canDeleteOrder;

	const saveOrder = () => {
		typeof saveCallRef.current === 'function' && saveCallRef.current();
	};

	const commonProps = {
		orderHeaderId: Number(orderHeaderId),
		onCancelOrder: orderIsCancellable
			? () => {
				setOpenModal({ type: 'CANCEL_MODAL' });
			}
			: undefined,
		onDeleteOrder: orderIsDeletable
			? () => {
				setOpenModal({ type: 'DELETE_MODAL' });
			}
			: undefined,
		setSaveCall: (fn: () => void) => {
			saveCallRef.current = fn;
		},
	};

	const orderStatus = orderData?.status || 'DRAFT';

	const orderRejectComment = orderData?.status === 'REJECTED' && orderData?.rejection_comment;

	const isFault =
		orderSummaryQuery.isError &&
		!((orderSummaryQuery.error as any).response?.status === 409);

	const orderHasBeenSubmitted =
		orderSummaryQuery.isError &&
		((orderSummaryQuery.error as any).response?.status === 409);
	return (
		<>
			<Page
				isLoading={orderSummaryQuery.isLoading}
				fault={(isFault && orderSummaryQuery.error) || companyProfileQuery.error}
			>
				{orderRejectComment && (
					<Alert variant="danger">
						This order was rejected with the the following comment: "
						{orderRejectComment}"
					</Alert>
				)}
				<Alert variant="warning" className="alert-icon">
					Please check that all requirements are met before ordering.{' '}
					<ActionLink onClick={() => setOpenModal({ type: 'INFO_MODAL' })}>
						View Requirements <FAIcon name="chevron-right" size="xs" />
					</ActionLink>
				</Alert>
				<Breadcrumb>
					<Breadcrumb.Item to="/orders/digital-codes">
						Digital Code Orders
					</Breadcrumb.Item>
					<Breadcrumb.Item active>Create New Digital Code Order</Breadcrumb.Item>
				</Breadcrumb>
				<Title
					subtitle={
						orderSummaryQuery.isError ? '' :
						step === STEP_1
							? 'Step 1: Order Info'
							: step === STEP_2
								? 'Step 2: Delivery'
								: step === STEP_3
									? 'Step 3: Order Confirmation'
									: ''
					}
					status={
						orderSummaryQuery.isError ? undefined :
						<StatusText
							badge
							variant={orderStatus === 'REJECTED' ? 'warning' : undefined}
						>
							{step && orderStatus}
						</StatusText>
					}
					button={
						orderSummaryQuery.isError ? undefined :
						<MeatballDropdown>
							{(step === STEP_1 || step === STEP_2) && (
								<MeatballDropdown.Item onClick={() => saveOrder()}>
									Save Order
								</MeatballDropdown.Item>
							)}
							<MeatballDropdown.Divider />
							{orderIsDeletable && (
								<MeatballDropdown.Item
									onClick={() => setOpenModal({ type: 'DELETE_MODAL' })}
								>
									<span className="text-danger">Delete Order</span>
								</MeatballDropdown.Item>
							)}
							{orderIsCancellable && (
								<MeatballDropdown.Item
									onClick={() => setOpenModal({ type: 'CANCEL_MODAL' })}
								>
									<span className="text-danger">Cancel Order</span>
								</MeatballDropdown.Item>
							)}
						</MeatballDropdown>
					}
				>
					Create New Digital Codes Order
				</Title>
				{orderHasBeenSubmitted ? (
					<Page.FullPageCol>
						<Alert variant="danger" className="alert-icon">
							This order has been submitted and is not editable at this time.
						</Alert>
						<div className="display-flex mt-2">
							<Button variant="outline-secondary" href="/orders/digital-codes">
								View Digital Codes Orders index <FAIcon name="chevron-right" />
							</Button>
							<Button
								variant="outline-secondary"
								href={`/orders/digital-codes/${orderHeaderId}`}
							>
								View details of this order <FAIcon name="chevron-right" />
							</Button>
							<Button variant="outline-secondary" href="/orders/digital-codes/create">
								Create a new order <FAIcon name="chevron-right" />
							</Button>
						</div>
					</Page.FullPageCol>
				) : (
					companyProfile &&
					(step === STEP_2 ? (
						<Step2Delivery
							onPrevious={() => setStep(STEP_1)}
							onNext={() => setStep(STEP_3)}
							{...commonProps}
						/>
					) : step === STEP_3 ? (
						<Step3DigitalCodesOrderConfirmation
							onPrevious={() => setStep(STEP_2)}
							{...commonProps}
						/>
					) : (
						<Step1OrderAndProducts
							onNewId={(orderHeaderId: number) => {
								orderHeaderId &&
									history.replace(
										`/orders/digital-codes/${String(orderHeaderId)}/create`,
									);
							}}
							onNext={() => setStep(STEP_2)}
							{...commonProps}
						/>
					))
				)}
			</Page>
			{orderData && (
				<DeleteConfirmationModal
					show={openModal?.type === 'CANCEL_MODAL'}
					closeModal={() => setOpenModal({})}
					confirmDelete={async () => {
						if (orderHeaderId) {
							try {
								await postOrderCancel(orderHeaderId);
								toasterNotify('Order canceled', 'success');
							} catch (error: unknown) {
								error instanceof Error &&
									toasterNotify(
										createMessageForError(error, 'canceling order'),
										'error',
										error,
									);
							} finally {
								setOpenModal({});
								history.push(`/orders/digital-codes/${orderHeaderId}`);
							}
						}
					}}
					title="Cancel order"
					deleteLabel="Cancel Order"
					message="Are you sure you want to cancel this order?"
				/>
			)}
			{orderData && (
				<DeleteConfirmationModal
					show={openModal?.type === 'DELETE_MODAL'}
					closeModal={() => setOpenModal({})}
					confirmDelete={async () => {
						if (orderHeaderId) {
							try {
								await deleteDigitalCodeOrder(orderHeaderId);
								toasterNotify('Order deleted', 'success');
							} catch (error: unknown) {
								error instanceof Error &&
									toasterNotify(
										createMessageForError(error, 'deleting order'),
										'error',
										error,
									);
							} finally {
								setOpenModal({});
								history.push('/orders/digital-codes');
							}
						}
					}}
					title="Delete order"
					deleteLabel="Delete"
					message="Are you sure you want to delete this order?"
				/>
			)}
			<SimpleModal
				size="lg"
				title="Information about the Digital Code order process"
				show={openModal && openModal.type === 'INFO_MODAL'}
				closeModal={() => setOpenModal({ ...openModal, type: void 0 })}
			>
				<div>
					<b>Payments:</b>
					<br />
					<p>
						Digital code orders can be completed by PayPal for orders less than $10,000.
						Orders in excess of $10,000 require a wire transfer for payment.
					</p>
					<p>
						<span style={{ background: '#FFFF33' }}>
							You are responsible for all wire transfer fees.
						</span>{' '}
						The final amount transferred to Nintendo of America must match the amount
						shown on the pro-forma invoice.
					</p>
					<b>Requirements to order:</b>
					<br />
					<ul>
						<li>Your game must have an NCMS entry with a permanent price.</li>
						<li>
							The price must be &lsquo;live&rsquo; but the game page can be hidden.
						</li>
						<li>
							The game/component must have a price greater than $0. Free items are not
							included.
						</li>
						<li>Codes are for use in the Americas only.</li>
					</ul>
					<b>Digital Code order process:</b>
					<br />
					<ol>
						<li>
							Select the game and component in Step 1. Individual components (AOC,
							consumables, subscriptions) are included.
						</li>
						<li>Enter delivery details in Step 2.</li>
						<li>A price for the order will be provided.</li>
						<li>Submit the order for review by Nintendo of America.</li>
						<li>
							After review, the order will have the status{' '}
							<StatusText>Awaiting Payment Info</StatusText>, and a pro-forma invoice
							will be provided.
						</li>
						<li>
							Select your payment type. PayPal payments are completed through the
							PayPal interface. The codes will be generated once the payment has been
							received.
						</li>
						<li>
							Once payment is complete Nintendo will attach the codes to the order.
						</li>
						<li>
							To retrieve the codes, access the order to download the code batch. The
							code batch is available to download for 30 days.
						</li>
					</ol>
				</div>
			</SimpleModal>
		</>
	);
};

export default DigitalCodesOrderCreate;
