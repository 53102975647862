import React, { Component } from 'react';
import { Alert, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FAIcon from '../../components/FAIcon/FAIcon';
import MeatballDropdown from '../../components/MeatballDropdown/MeatballDropdown';
import Page from '../../components/Page/Page';
import StatefulTabs from '../../components/StatefulTabs/StatefulTabs';
import Title from '../../components/Title/Title';
import { companyAgreementConstants } from '../../constants/companyAgreementConstants';
import { permConst } from '../../constants/permConst';
import { getCompanyAgreements, getCompanyProfile } from '../../services/companiesService';
import { getAssociatedPackoutFacilities } from '../../services/ordersService';
import { createMessageForError, toasterNotify } from '../../utils/toaster';
import { isAuthorized, isAuthorizedForUrl } from '../../utils/userUtils';
import EditCompanyProjectabilityModal from './modals/EditCompanyProjectabilityModal';
import EditNoteModal from './modals/EditNoteModal';
import MakerCodeModal from './modals/MakerCodeModal';
import SkuRequiredModal from './modals/skuRequiredModal';
import ActiveFeaturesTab from './views/ActiveFeaturesTab';
import AgreementsTab from './views/AgreementsTab';
import CompanyInfoTab from './views/CompanyInfoTab';
import PackoutFacilitiesTab from './views/PackoutFacilitiesTab';
import { getCustomFields } from '../../services/customFieldsService';
import {
	EditCustomFieldsModal
} from '../../components/modals/EditCustomFieldModal/EditCustomFieldsModal';


const COMPANY_INFO_TAB = 'company-info';
const AGREEMENTS_TAB = 'agreements';
const PACKOUT_FACILITIES_TAB = 'packout-facilities';
const ACTIVE_FEATURES_TAB = 'active-features';

function mapStateToProps(state) {
	return {
		userProfile: state.authReducer.userProfile
	};
}

export class CompanyProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			modalType: '',
			titleOptions: {},
			skuRequired: false,
			companyData: null,
			facilitiesData: [],
			customFields: [],
			isLoading: true
		};
		this.toggleModal = this.toggleModal.bind(this);
		this.renderModal = this.renderModal.bind(this);
		this.checkOptions = this.checkOptions.bind(this);
		this.getCustomFields = this.getCustomFields.bind(this);
		this.updateCompanyInfo = this.updateCompanyInfo.bind(this);
		this.updateNote = this.updateNote.bind(this);
		this.updateProjectability = this.updateProjectability.bind(this);
		this.updateMakerCode = this.updateMakerCode.bind(this);
	}

	toggleModal(key) {
		let type = null;
		if (!this.state.showModal) {
			type = key;
		}
		this.setState({
			showModal: !this.state.showModal,
			modalType: type
		});
	}

	renderModal() {
		const { userProfile } = this.props;
		const { companyData, customFields } = this.state;
		let skuChecked =
			companyData.company_information.sku_number_required !== 0;

		switch (this.state.modalType) {
			case 'skuRequired':
				return (
					<SkuRequiredModal
						userProfile={userProfile}
						companyProfile={companyData}
						closeFunction={this.toggleModal}
						skuCheck={skuChecked}
						updateCompanyInfo={this.updateCompanyInfo}
					/>
				);
			case 'makercode':
				return (
					<MakerCodeModal
						userProfile={userProfile}
						companyProfile={companyData}
						closeFunction={this.toggleModal}
						updateMakerCode={this.updateMakerCode}
					/>
				);
			case 'editNote':
				return (
					<EditNoteModal
						userProfile={userProfile}
						companyProfile={companyData}
						closeFunction={this.toggleModal}
						updateNote={this.updateNote}
					/>
				);
			case 'editCompanyProjectability':
				return (
					<EditCompanyProjectabilityModal
						userProfile={userProfile}
						companyProfile={companyData}
						closeFunction={this.toggleModal}
						updateProjectability={this.updateProjectability}
					/>
				);
			case 'editCustomFields':
				return (
					<EditCustomFieldsModal
						show={true}
						onClose={this.toggleModal}
						onCompletion={this.getCustomFields}
						customFields={customFields}
						entityId={companyData.company_information.ndid_company_id}
					/>
				);
			default:
				break;
		}
	}

	//  Company Title Option Functions
	checkOptions(agreements) {
		const { match, userProfile } = this.props;
		let showSkuOption = false;
		if (userProfile.companyId === match.params.companyId) {
			// We only want to show Required SKU Option if the company has at least one retail agreement
			for (let agree of agreements) {
				if (agree.agreementType === companyAgreementConstants.TYPE.RETAIL) {
					showSkuOption = true;
					break;
				}
			}
		}
		let currOptions = this.state.titleOptions;
		currOptions['skuRequired'] = showSkuOption;
		this.setState({
			titleOptions: currOptions
		});
	}

	renderTitleButton(userProfile) {
		const { titleOptions } = this.state;

		const canEditSku = isAuthorized(userProfile.permissions, [
			permConst.COMPANY.PART_SKU.EDIT.COMPANY
		]);

		const showEditNote = isAuthorized(userProfile.permissions, [
			permConst.COMPANY.NOTE.EDIT.ALL
		]);

		const showEditPartSku = titleOptions.skuRequired;

		const showEditMakerCode = isAuthorized(userProfile.permissions, [
			permConst.COMPANY.MAKER_CODE.EDIT.ALL
		]);

		const showEditProjectionEligibleFlag = isAuthorized(
			userProfile.permissions,
			[permConst.COMPANY.PROJECTABILITY.EDIT.ALL]
		);

		const showSeparator =
			(showEditNote || showEditPartSku) && showEditMakerCode;

		const canViewCustomFields = isAuthorized(userProfile.permissions, [
			permConst.CUSTOM_FIELD.VIEW.ALL
		]);

		return (
			<MeatballDropdown
				id={'company-context-menu'}
			>
				{showEditNote && (
					<MeatballDropdown.Item
						eventKey="editNote"
						id="editNote"
						onSelect={(key) => this.toggleModal(key)}
					>
						Edit Note
					</MeatballDropdown.Item>
				)}
				{showEditPartSku && (
					<MeatballDropdown.Item
						eventKey="skuRequired"
						id="skuRequired"
						onSelect={(key) => this.toggleModal(key)}
						disabled={!canEditSku}
					>
						Edit Part/SKU Number Required
					</MeatballDropdown.Item>
				)}
				{showSeparator && <MeatballDropdown.Divider />}
				{showEditMakerCode && (
					<MeatballDropdown.Item
						eventKey="makercode"
						id="makercode"
						onSelect={(key) => this.toggleModal(key)}
					>
						Edit Maker Code
					</MeatballDropdown.Item>
				)}
				{canViewCustomFields && (
					<MeatballDropdown.Item
						eventKey="editCustomFields"
						id="editCustomFields"
						onSelect={(key) => this.toggleModal(key)}
					>
						Edit Custom Fields
					</MeatballDropdown.Item>
				)}
				{showEditProjectionEligibleFlag && (
					<MeatballDropdown.Item
						eventKey="editCompanyProjectability"
						id="editCompanyProjectability"
						onSelect={(key) => this.toggleModal(key)}
					>
						Edit Company Projectability
					</MeatballDropdown.Item>
				)}
			</MeatballDropdown>
		);
	}

	getPackoutFacilities() {
		const { userProfile, match } = this.props;

		const canViewPackoutFacilities = isAuthorized(userProfile.permissions, [
			permConst.COMPANY.PACKOUT_FACILITY.VIEW.ALL,
			permConst.COMPANY.PACKOUT_FACILITY.VIEW.COMPANY
		]);

		//Only display packout facilities to users who are allowed to see them.
		if (!canViewPackoutFacilities) {
			return;
		}

		return getAssociatedPackoutFacilities(
			match.params.companyId
		)
			.then((response) => {
				this.setState({
					facilitiesData: response.data
				});
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving facilities'),
					'error',
					error
				);
			});
	}

	getCompanyAgreements() {
		let { match } = this.props;
		return getCompanyAgreements(
			match.params.companyId
		).then((response) => {
			this.checkOptions(response.data);
			this.setState({
				companyAgreements: response.data
			});
		});
	}

	getCompanyProfile() {
		let { match } = this.props;

		return getCompanyProfile(
			match.params.companyId
		).then((response) => {
			this.setState({
				companyData: response.data
			});
		});
	}

	getCustomFields() {
		const { userProfile, match } = this.props;
		const canViewCustomFields = isAuthorized(userProfile.permissions,
			[permConst.CUSTOM_FIELD.VIEW.ALL]);
		if (!canViewCustomFields) {
			return;
		}
		return getCustomFields(
			'company',
			match.params.companyId
		).then((response) => {
			this.setState({
				customFields: response.data
			});
		}).catch((error) => {
			toasterNotify(
				createMessageForError(error, 'retrieving custom fields'),
				'error',
				error
			);
		});
	}

	updateCompanyInfo(key, value) {
		let newCompanyData = this.state.companyData;
		newCompanyData.company_information[key] = value;
		this.setState({
			companyData: newCompanyData
		});
	}

	updateMakerCode(makerCode) {
		let newCompanyData = this.state.companyData;
		newCompanyData.company_information.maker_code = makerCode;
		this.setState({
			companyData: newCompanyData
		});
	}

	updateNote(note) {
		let newCompanyData = this.state.companyData;
		newCompanyData.company_information.comment = note;
		this.setState({
			companyData: newCompanyData
		});
	}

	updateProjectability(canMakeProjections) {
		let newCompanyData = this.state.companyData;

		newCompanyData.company_information.projection_eligible_flag = canMakeProjections;

		this.setState({
			companyData: newCompanyData
		});
	}

	loadResources() {
		this.setState({
			isLoading: true
		});

		Promise.all([
			this.getCompanyProfile(),
			this.getPackoutFacilities(),
			this.getCompanyAgreements(),
			this.getCustomFields()
		]).then(() => {
			this.setState({
				isLoading: false
			});
		});
	}

	componentDidMount() {
		this.loadResources();
	}

	componentDidUpdate() {
		const { isLoading, companyData } = this.state;
		const { match } = this.props;
		if (
			!isLoading &&
				companyData?.company_information?.ndid_company_id &&
				match.params.companyId !== companyData?.company_information?.ndid_company_id
		) {
			this.loadResources();
		}
	}

	render() {
		const { userProfile } = this.props;
		const { companyAgreements } = this.state;

		const {
			companyData,
			facilitiesData,
			customFields,
			showModal,
			isLoading
		} = this.state;
		const title = companyData
			? companyData.company_information.company_name
			: '';
		const canViewPackoutFacilities = isAuthorized(userProfile.permissions, [
			permConst.PACKOUT_FACILITY.VIEW.COMPANY
		]);
		const canViewCompanyManagement = isAuthorizedForUrl('/admin/publishers');
		const canViewFeatures = isAuthorized(userProfile.permissions, [
			permConst.FEATURE_FLAG.VIEW.ALL
		]);

		return (
			<Page isLoading={isLoading}>
				<Alert variant="info">
					To add a part number requirement for orders, select the ellipses (
					<FAIcon name="ellipsis-h" />
					) menu.
					<br />
					Company information on this screen reflects information on the Nintendo
					Developer Portal. Changes to the information must be completed in the Nintendo
					Developer Portal.
				</Alert>
				{ canViewCompanyManagement &&
					<Breadcrumb>
						<Breadcrumb.Item to="/admin/publishers">Publisher Management</Breadcrumb.Item>
						<Breadcrumb.Item active>{title}</Breadcrumb.Item>
					</Breadcrumb>
				}
				<Title title={title} button={this.renderTitleButton(userProfile)} />

				<StatefulTabs transition={false} retainPageState>
					<Tab
						eventKey={COMPANY_INFO_TAB}
						title="Company Information"
						className="Page__fill-space"
					>
						<Page.ContentContainer>
							<CompanyInfoTab
								companyData={companyData}
								customFields={customFields}
							/>
						</Page.ContentContainer>
					</Tab>
					<Tab eventKey={AGREEMENTS_TAB} title="Agreements" className="Page__fill-space">
						<Page.ContentContainer>
							<AgreementsTab
								companyAgreements={companyAgreements}
							/>
						</Page.ContentContainer>
					</Tab>
					{canViewPackoutFacilities && (
						<Tab
							eventKey={PACKOUT_FACILITIES_TAB}
							title="Packout Facilities"
							className="Page__fill-space"
						>
							<Page.ContentContainer>
								<PackoutFacilitiesTab facilitiesData={facilitiesData} />
							</Page.ContentContainer>
						</Tab>
					)}
					{canViewFeatures && (
						<Tab
							eventKey={ACTIVE_FEATURES_TAB}
							title="Active Features"
							className='Page__fill-space'
						>
							<Page.ContentContainer>
								<ActiveFeaturesTab
									companyData={companyData} />
							</Page.ContentContainer>
						</Tab>
					)}
				</StatefulTabs>
				{showModal && this.renderModal()}
			</Page>
		);
	}
}

export default connect(mapStateToProps)(CompanyProfile);
