import React, {
	DetailedHTMLProps,
	HTMLAttributes,
	VFC,
	createRef,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	Cell,
	CellContext,
	ColumnDef,
	ColumnSort,
	DisplayColumnDef,
	Header,
	OnChangeFn,
	PaginationState,
	Row,
	SortingState,
	VisibilityState,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { ComponentPropsGetter0, ComponentPropsGetterR } from 'react-table';

import { useHistory, useLocation } from 'react-router-dom';
import { useUiStates } from '../../hooks/reduxHooks';
import eventBus from '../../utils/eventBus';
import { getLocation } from '../../utils/routeUtils';
import FAIcon from '../FAIcon/FAIcon';
import { TabState } from '../StatefulTabs/StatefulTabs';
import NoDataView from './views/NoDataView';
import PaginationView from './views/PaginationView';

import './BaseTable.css';
import './react-table.css';

const DEFAULT_PAGE_SIZE = 25;

const COLUMN_DEF_KEYS = [
	'accessorKey',
	'accessorFn',
	'aggregatedCell',
	'aggregationFn',
	'cell',
	'columns',
	'enableColumnFilter',
	'enableGlobalFilter',
	'enableGrouping',
	'enableHiding',
	'enableMultiSort',
	'enablePinning',
	'enableResizing',
	'enableSorting',
	'filterFn',
	'footer',
	'getGroupingValue',
	'getUniqueValues',
	'header',
	'id',
	'invertSorting',
	'maxSize',
	'meta',
	'minSize',
	'size',
	'sortDescFirst',
	'sortingFn',
	'sortUndefined',
];

export const actionsColumn: ColumnDef<unknown, unknown> = {
	id: 'actions',
	size: 53,
	enableSorting: false,
	enableResizing: false,
};

export const linkColumn: ColumnDef<unknown, unknown> = {
	id: 'view-link',
	size: 35,
	enableSorting: false,
	enableResizing: false,
};

const columnHelper = createColumnHelper<unknown>();

const makeColumnDef = (object: Record<string, any>): [ColumnDef<unknown, unknown>, boolean] => [
	pick(object, COLUMN_DEF_KEYS) as ColumnDef<unknown, unknown>,
	difference(Object.keys(object), COLUMN_DEF_KEYS).length > 0,
];

const convertToNewColumnFormat = (
	columns: Record<string, any>[],
): [ColumnDef<unknown, unknown>[], VisibilityState] => {
	const converted: any[] = [];
	const columnVisibility: VisibilityState = {};
	columns.forEach((original, index) => {
		const [picked, deviated] = makeColumnDef(original);
		const id =
			original.id ||
			(typeof original.accessor === 'string' ? original.accessor : `no-id-column-${index}`);
		columnVisibility[id] = original.show !== false;
		if (deviated) {
			// deviated columns defs are those that do not use strictly tanstack 8 column def 
			// (COLUMN_DEF_KEYS) properties. We will assume these use props meant for react table 6.
			if (
				original.accessor &&
				(typeof original.accessor === 'string' || typeof original.accessor === 'function')
			) {
				const newColumn = columnHelper.accessor(
					original.accessor,
					pickBy(
						{
							id,
							header: original.Header,
							cell:
								original.cell ||
								(original.Cell
									? (info: CellContext<unknown, unknown>) => (
										<original.Cell
											{...{
												value: info.getValue(),
												original: info.row.original,
												column: info.column.columnDef,
											}}
										/>
									)
									: (info: CellContext<unknown, unknown>) => (info.getValue()) || null),
							size: original.width,
							maxSize: original.maxWidth,
							enableResizing: original.resizable,
							sortingFn:
								typeof original.sortMethod === 'function'
									? (a: Row<unknown>, b: Row<unknown>) => {
										const aValue = a.getValue(
											original.id || original.accessor,
										);
										const bValue = b.getValue(
											original.id || original.accessor,
										);
										return original.sortMethod(aValue, bValue);
									}
									: 'default',
							...picked,
						},
						(x) => x !== undefined,
					),
				);
				return converted.push(newColumn);
			} else if (original.id && original.Cell && !original.accessor) {
				const newColumn = columnHelper.display(
					pickBy(
						{
							header: original.Header,
							cell:
								original.cell ||
								(original.Cell
									? (info: CellContext<unknown, unknown>) => (
										<original.Cell
											{...{
												value: info.getValue(),
												original: info.row.original,
												column: info.column.columnDef,
											}}
										/>
									)
									: (info: CellContext<unknown, unknown>) => info.getValue()),
							size: original.width,
							maxSize: original.maxWidth,
							enableResizing: original.resizable,
							id: original.id,
							...picked,
						},
						(x) => x !== undefined,
					) as DisplayColumnDef<unknown>,
				);
				return converted.push(newColumn);
			}
		} else {
			return picked;
		}
	});
	return [converted as ColumnDef<unknown, unknown>[], columnVisibility as VisibilityState];
};

interface BaseTableProps extends HTMLAttributes<any> {
	className?: string;
	columns: ColumnDef<unknown, unknown>[] | Record<string, any>[];
	data?: any[];

	minRows?: number;
	defaultSorted?: ColumnSort[];
	showPagination?: boolean;
	defaultPageSize?: number;
	sortable?: boolean;
	getTrProps?: ComponentPropsGetterR | ComponentPropsGetter0;

	retainPageState?: boolean;
	stateOnTab?: string | false;
	allowOverflow?: boolean;
}
const BaseTable: VFC<BaseTableProps> = ({
	allowOverflow,
	className,
	columns,
	data,
	defaultPageSize = DEFAULT_PAGE_SIZE,
	defaultSorted,
	retainPageState,
	showPagination,
	stateOnTab,
}) => {
	const [initialized, setInitialized] = useState<boolean>(false);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: defaultPageSize,
	});
	const [sorting, setSorting] = useState<SortingState>(defaultSorted || []);

	// retain page state related objects
	const history = useHistory();
	const location = useLocation();
	const uiStates = useUiStates(!!stateOnTab);
	const storedUiStates = uiStates.get();
	const currentTab = useContext(TabState)?.tab;
	const retainStateActive =
		(retainPageState && currentTab === stateOnTab) || (retainPageState && !stateOnTab);

	const maxPageIndex = Math.floor((data?.length || 0) / pagination.pageSize);
	// component should use these values for render so that we can override state values for the current render
	let currentPagination: PaginationState = pagination,
		currentSorting: SortingState | [] = sorting;

	// for changing the pagination state when it occurs within this component
	const onPaginationChange: OnChangeFn<PaginationState> = (newPagination) => {
		if (retainStateActive) {
			const newPaginationState =
				typeof newPagination === 'function' ? newPagination(pagination) : newPagination;

			const newRowLimit = newPaginationState.pageSize;
			const newPageIndex = newPaginationState.pageIndex;

			if (newRowLimit === DEFAULT_PAGE_SIZE) {
				if (storedUiStates.rows != null) {
					uiStates.push('rows', null);
				}
			} else {
				if (storedUiStates.rows !== String(newRowLimit))
					uiStates.push('rows', String(newRowLimit));
			}
			updateSearch({ rows: newRowLimit, page: newPageIndex });
		}
		setPagination(newPagination);
	};

	// for changing the sorting state when it occurs within this component
	const onSortingChange: OnChangeFn<SortingState> = (newSorting) => {
		if (retainStateActive) {
			const newSortingState =
				typeof newSorting === 'function' ? newSorting(sorting) : newSorting;
			const newSortString = serializeSort([...newSortingState]);
			const defaultSortString = serializeSort(defaultSorted);

			if (newSortString == null && newSortString !== defaultSortString) {
				uiStates.push('sort', null);
			} else {
				uiStates.push('sort', newSortString);
			}

			updateSearch({ 'sort': newSortingState });
		}
		setSorting(newSorting);
	};

	const onFiltersChange = () => {
		setPagination({ ...pagination, pageIndex: 0 });
	};

	const initializeState = () => {
		setInitialized(true);
		if (initialized) {
			return;
		}
		if (retainPageState) {
			if (location.search) {
				const initialSearch: URLSearchParams | null | undefined = retainPageState
					? new URLSearchParams(location.search)
					: null;

				// use query string for values
				currentPagination.pageIndex = initialSearch?.has('page')
					? Number(initialSearch?.get('page'))
					: currentPagination.pageIndex;
				currentPagination.pageSize = Number(initialSearch?.get('rows')) || defaultPageSize;
				currentSorting =
					(unserializeSort(initialSearch?.get('sort')) as SortingState) || sorting;
			} else {
				// use storedUIStates
				currentPagination = {
					pageSize: Number(storedUiStates.rows) || defaultPageSize,
					pageIndex: 0,
				};
				currentSorting =
					(unserializeSort(storedUiStates.sort) as SortingState) || sorting;


				!stateOnTab &&
					updateSearch({
						page: currentPagination.pageIndex,
						rows: currentPagination.pageSize,
						sort: currentSorting,
					});
			}
			setPagination(currentPagination);
			setSorting(currentSorting);
		}
	};

	const unserializeSort = (string: string | null | undefined): SortingState | undefined => {
		if (string === 'none') {
			return [];
		}
		return string ? [{ id: string.replace(/^desc-/, ''), desc: string.substring(0, 5) === 'desc-' }] : undefined;
	};
	const serializeSort = (sort?: SortingState): string | undefined =>
		sort && sort.length ? `${sort[0].desc ? 'desc-' : ''}${sort[0].id}` : undefined;

	const serializedState = (
		initialState: string,
		{ page, rows, sort }: { page?: number; rows?: number; sort?: SortingState },
		options: { defaultPageSize?: number, defaultSorting?: SortingState } = {},
	) => {
		const searchParams = new URLSearchParams(initialState);
		if (page && page !== 0) {
			page && searchParams.set('page', String(page + 1));
		} else {
			searchParams.delete('page');
		}
		if (rows && rows !== (options.defaultPageSize || DEFAULT_PAGE_SIZE)) {
			searchParams.set('rows', String(rows));
		} else {
			searchParams.delete('rows');
		}
		if (sort !== undefined) {
			const sortValue = serializeSort(sort);
			const defaultSortValue = serializeSort(options.defaultSorting);
			if (sortValue === defaultSortValue) {
				searchParams.delete('sort');
			} else if (!sortValue) {
				searchParams.set('sort', 'none');
			} else {
				searchParams.set('sort', sortValue);
			}
		}
		return searchParams.toString();
	};

	const updateSearch = debounce((change: { page?: number; rows?: number; sort?: SortingState }) => {
		if (retainPageState && retainStateActive) {
			const newSearchString = serializedState(getLocation().search, change, {
				defaultPageSize, defaultSorting: defaultSorted
			});
			if (location.search !== newSearchString) {
				history.replace(location.pathname + '?' + newSearchString);
			}
		}
	}, 1);

	useEffect(() => {
		if (retainPageState && !retainStateActive) {
			// reset the state to blank. Rely on StatefulTabs repopulating the
			// query string after a tab change.
			setInitialized(false);
			setPagination({
				pageIndex: 0,
				pageSize: defaultPageSize,
			});
			setSorting([]);
		} else if (!initialized && !!data) {
			initializeState();
		}
	}, [retainStateActive]);

	// if filters change, auto change page index to first page
	useEffect(() => {
		const ref = createRef();
		eventBus.on(eventBus.FILTER_CHANGE, () => onFiltersChange(), ref);
		return () => eventBus.off(ref);
	}, []);

	// last minute check on page index
	if (currentPagination.pageIndex > maxPageIndex) {
		currentPagination.pageIndex = maxPageIndex;
		setPagination(currentPagination);
		if (retainStateActive) {
			updateSearch({
				page: currentPagination.pageIndex
			});
		}
	}

	// pre-render and render

	const [convertedColumns, columnVisibility] = convertToNewColumnFormat(columns);

	const table = useReactTable({
		data: data || [],
		columns: convertedColumns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		...(showPagination !== false ? { getPaginationRowModel: getPaginationRowModel() } : null),

		onSortingChange,
		sortingFns: {
			'default': (rowA, rowB, column) => {
				const toLowerCaseIfString = (value: any) => typeof value === 'string' ? value.toLowerCase() : value;
				const a = toLowerCaseIfString(rowA.getValue(column));
				const b = toLowerCaseIfString(rowB.getValue(column));
				// null/undefined always sorted as smallest value
				if (a == null && b == null) {
					return 0;
				}
				return a == null ? -1 : b == null ? 1 : a < b ? -1 : a > b ? 1 : 0;
			}
		},

		enableColumnResizing: true,
		columnResizeMode: 'onChange',

		defaultColumn: {
			size: 100, //starting column size
			minSize: 35,
			maxSize: 500,
		},
		state: {
			...(showPagination !== false ? { pagination: currentPagination } : null),
			sorting: currentSorting,
			columnVisibility: columnVisibility,
		},

	});

	const getFlexStyles = (cell: Cell<unknown, unknown> | Header<unknown, unknown>) => {
		const size =
			'getSize' in cell
				? cell.getSize()
				: 'getSize' in cell.column
					? cell.column.getSize()
					: 0;

		if (size === 0) {
			return {};
		}

		return {
			flex: `${cell.column.getCanResize() ? size : 0} 0 auto`,
			width: `${size}px`,
		} as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
	};

	return !data ? null : (
		<div
			className={`BaseTable ${allowOverflow ? 'BaseTable--allow-overflow' : 'Page__fill-space'
				}`}
		>
			<div className={`ReactTable${className ? ' ' + className : ''}`}>
				<div className="rt-table" role="grid">
					<div className="rt-thead -header">
						{table.getHeaderGroups().map((headerGroup, index) => (
							<div className="rt-tr -header" role="row" key={'header-group-' + index}>
								{headerGroup.headers.map((header, index) => (
									<div
										className={`rt-th${header.column.getCanResize()
											? ' rt-resizable-header'
											: ''
											}`}
										role="row"
										key={'header-' + index}
										style={getFlexStyles(header)}
									>
										{header.isPlaceholder ? null : (
											<div
												className={
													header.column.getCanSort()
														? '-cursor-pointer select-none'
														: ''
												}
												onClick={header.column.getToggleSortingHandler()}
											>
												{flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
												&nbsp;&nbsp;
												{header.column.getIsSorted() ? (
													<FAIcon className="BaseTable__sort-icon"
														name={
															(header.column.getIsSorted() as string) ===
																'asc'
																? 'angle-up'
																: 'angle-down'
														}
													/>
												) : <>&nbsp;&nbsp;&nbsp;</>}
											</div>
										)}

										{header.column.getCanResize() && (
											<div
												onMouseDown={header.getResizeHandler()}
												onTouchStart={header.getResizeHandler()}
												className={`resizer${header.column.getIsResizing()
													? ' isResizing'
													: ''
													}`}
												onDoubleClick={() => header.column.resetSize()}
											></div>
										)}
									</div>
								))}
							</div>
						))}
					</div>
					<div className="rt-tbody">
						{table.getRowCount() === 0 ? (
							<NoDataView>No items to display</NoDataView>
						) : (
							table.getRowModel().rows.map((row, rowIndex) => (
								<div className="rt-tr-group" role="rowgroup" key={'row-' + (row.id || rowIndex)}>
									<div
										className={`rt-tr ${rowIndex % 2 ? '-even' : '-odd'}`}
										role="row"
									>
										{row.getVisibleCells().map((cell, index) => (
											<div
												className="rt-td"
												role="gridcell"
												key={'cell' + index}
												style={getFlexStyles(cell)}
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</div>
										))}
									</div>
								</div>
							))
						)}
					</div>
				</div>
				{showPagination !== false && data.length > 0 && (
					<PaginationView
						canPrevious={pagination.pageIndex > 0}
						canNext={
							pagination.pageIndex + 1 < Math.ceil(data.length / pagination.pageSize)
						}
						showPageJump={true}
						onPageChange={(newPageIndex) => {
							onPaginationChange({ ...pagination, pageIndex: newPageIndex });
							// TODO: investigate why search string changes in onPaginationChange 
							// triggers another page index change to 0
							// table.setPageIndex(newPageIndex);
						}}
						onPageSizeChange={(newPageSize, newPageIndex) => {
							onPaginationChange({ pageSize: newPageSize, pageIndex: newPageIndex });
							// table.setPageSize(newPageSize);
						}}
						page={pagination.pageIndex}
						pages={Math.ceil(data.length / pagination.pageSize)}
						pageSize={pagination.pageSize}
					/>
				)}
			</div>
		</div>
	);
};
export default BaseTable;
