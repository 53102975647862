import { orderConst } from '../../constants/orderConstants';
export type CommentType = (typeof orderConst.COMMENT)[keyof typeof orderConst.COMMENT];
export const commentLabels: Record<CommentType | string, string> = {
	PUBLISHER: 'Comment from Publisher',
	CANCEL: 'Cancellation',
	NOTES_TO_NCL: 'Comments to NCL',
	REJECTION: 'Rejection',
	REVIEWER: 'Comments to PO Approver',
	SHIPPING_INFO: 'Shipping Instructions',
};

export const getReasonForInvalidValue = (
	subject: string,
	invalidData?: InvalidData | null,
) => {
	if (!invalidData?.invalid) {
		return undefined;
	}
	switch (invalidData.code) {
		// quantity errors
		case 'INVALID_QUANTITY_PARTIAL_CASE':
			return 'The item quantity must be in multiples of the quantity per case.';
		case 'INVALID_QUANTITY_INITIAL_ORDER_MIN':
		case 'INVALID_QUANTITY_ORDER_MIN':
			return 'The quantity is below the minimum for this product';

		// product selection errors
		case 'PRODUCT_IDS_NOT_UNIQUE':
			return 'Another entry for this product already exists and duplicates are not allowed';
		case 'PLATFORM_CODE_MISMATCH':
			return `The platform for this ${subject} is mismatched and cannot be placed on this order`;
		case 'RETAIL_RIGHTS_TRANSFER_IN_PROCESS':
			return `The ${subject} is undergoing a rights transfer that is preventing the order from progressing`;
		case 'NO_RETAIL_RIGHTS':
			return `The order placer does not have the rights to order this ${subject}`;
		case 'NOA_PART_NUMBER_NOT_FOUND':
			return `The ${subject} does not have an NOA part number on file. Please contact NOA via email.`;

		// submission
		case 'INVALID_SUBMISSION_STATUS':
			return 'This version does not currently have an orderable status';
		case 'INVALID_SUBMISSION_VERSION':
			return 'This version is outdated and needs to be updated to a newer version';
		case 'PRODUCT_ID_MISMATCH':
			return 'This version is not valid for the product and needs to be changed';
		case 'PUBLISHER_PART_NUMBER_NOT_FOUND':
			return `The part number for this ${subject} was not found. Please add one on the product's detail page.`;

		// shipping detail errors
		case 'CONSIGNEE_COMPANY_ID_NOT_ALLOWED':
			return 'A consignee cannot be assigned to this order and will need to be removed';
		case 'NOT_ASSOCIATED_TO_COMPANY':
			return `This ${subject} is not associated with the order placer and will need to be updated`;

		// generic error
		case 'CONSIGNEE_COMPANY_NOT_FOUND':
		case 'FREIGHT_FORWARDER_NOT_FOUND':
		case 'PACKOUT_FACILITY_NOT_FOUND':
		case 'PORT_OF_ENTRY_NOT_FOUND':
		case 'PRODUCT_NOT_FOUND':
		case 'SUBMISSION_NOT_FOUND':
			return `The entered ${subject} was not found in our records and will need to be changed`;
		case 'NULL_NOT_VALID':
			return subject === 'consignee' ?
				'The entered consignee is no longer valid and will need to be replaced' :
				`The value for ${subject} cannot be blank`;
		case 'NOT_ACTIVE':
		case 'INVALID_VALUE':
		case '':
		default:
	}
	return `The entered ${subject} is no longer valid and will need to be replaced`;
};
