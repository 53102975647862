import React, { useMemo, VFC } from 'react';
import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';

import { digitalCodesConstants } from '../../constants/digitalCodesConstants';
import { DigitalOrderData } from '../../services/digitalCodesService';
import { formatCurrency, numberWithCommas } from '../../utils/currencyUtils';
import { getPossiblyInvalidValue } from '../../utils/dataUtils';
import { dateFormat, formatDate, now, parseDateString } from '../../utils/dateUtils';
import {
	formatTaxPercentageDisplay,
	getCommentByType,
	getExpirationDate
} from '../../utils/digitalCodesUtils';
import ActorDisplay from '../ActorDisplay/ActorDisplay';
import InvalidDataErrorDisplay from '../InvalidDataErrorDisplay/InvalidDataErrorDisplay';
import LoadingText from '../Loading/LoadingText';
import PropertyDisplay from '../PropertyDisplay/PropertyDisplay';
import SectionTitle from '../SectionTitle/SectionTitle';

import '../../containers/DigitalCodesOrderCreate/views/Step1OrderAndProducts.css';

const {
	CODES_AVAILABLE,
	COMPLETE,
} = digitalCodesConstants.status;

interface DigitalCodesOrderProps {
	order?: Partial<DigitalOrderData>;
	pricesAreLoading?: boolean;
	productsComponents?: {
		productID?: number | InvalidData<number | null>;
		game_code?: string;
		game_name?: string;
		componentGameName?: string;
		componentID?: string | InvalidData<string | null>;
	};
	productsComponentsAreLoading?: boolean;
	publisher?: string;
	publisherIsLoading?: boolean;
	purposeName?: string;
	totalPrice?: string;
	unitPrice?: string;
	showErrors?: boolean;
	priceInformation?: {
		taxPercent: string;
		taxAmount: string;
		grandTotal: string;
		hasTotalPriceMismatch: boolean;
		hasUnitPriceFeeMismatch: boolean;
	};
	linkSubmittersEmail?: boolean;
	orderManagerEmails?: string[];
}
const DigitalCodesOrder: VFC<DigitalCodesOrderProps> = ({
	order,
	pricesAreLoading = false,
	productsComponents,
	productsComponentsAreLoading = false,
	publisher,
	publisherIsLoading,
	purposeName,
	totalPrice,
	unitPrice,
	showErrors = false,
	priceInformation,
	linkSubmittersEmail,
	orderManagerEmails,
}) => {
	const {
		codes_downloaded_by_userName,
		codes_downloaded_date,
		codes_upload_date,
		ebsSalesOrderNum,
		order_comments,
		paymentTransID,
		paymentType,
		publisherPO,
		purposeDescription,
		quantity,
		reqActivationDate,
		reqDeliveryDate,
		status,
		purposeID,
	} = order || {};
	const { game_code, game_name, componentGameName, componentID, productID } = productsComponents || {};
	const { taxPercent, taxAmount, grandTotal, hasUnitPriceFeeMismatch = false, hasTotalPriceMismatch = false } = priceInformation || {};

	const rejectComment = getCommentByType(order_comments, 'REJECTION');
	const reviewerComment = getCommentByType(order_comments, 'REVIEWER');
	const publisherComments = getCommentByType(order_comments, 'PUBLISHER');

	const taxPercentDisplay = useMemo(
		() => taxPercent && formatTaxPercentageDisplay(taxPercent),
		[taxPercent],
	);
	const [taxDisplay, grandTotalDisplay] = useMemo(
		() => {
			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			return [
				(taxPercentDisplay && formatter.format(Number(taxAmount))) || null,
				(grandTotal && formatter.format(Number(grandTotal))) || null
			];
		}, [taxPercentDisplay, grandTotal],
	);
	const formatUnitPriceDisplay = () => {
		const unitPriceDisplay = unitPrice === 'Fee' ? <i>Fee</i> : formatCurrency(unitPrice, 4) + ' / unit';
		if (hasUnitPriceFeeMismatch) {
			return <span className="text-danger">{unitPriceDisplay}<sup>&dagger;</sup></span>;
		}
		return unitPriceDisplay;
	};

	return (
		<>
			<SectionTitle>Order Details</SectionTitle>
			<div>
				{(publisherIsLoading || publisher) && (
					<PropertyDisplay label="Publisher">
						{publisherIsLoading ? <LoadingText /> : publisher}
						{!publisherIsLoading && orderManagerEmails && (
							<>
								{' | '}
								<a
									href={`mailto:${orderManagerEmails.join(
										',',
									)}?subject=Regarding%20order%20${order?.publisherPO}`}
								>
									Email all order managers at publisher
								</a>
							</>
						)}
					</PropertyDisplay>
				)}
				<PropertyDisplay label="Sales Order Number">{ebsSalesOrderNum}</PropertyDisplay>
				<PropertyDisplay label="Purpose">
					{purposeName}
					{showErrors && <InvalidDataErrorDisplay
						subject="purpose"
						value={purposeID}
					/>}
				</PropertyDisplay>
				<PropertyDisplay label="Description of Purpose">
					{purposeDescription}
				</PropertyDisplay>
				<PropertyDisplay label="Publisher PO Number">{publisherPO}</PropertyDisplay>
				{paymentType && (
					<>
						<PropertyDisplay label="Payment Type">
							{paymentType === 'PAYPAL'
								? 'PayPal'
								: paymentType === 'WIRE'
									? 'Wire Transfer'
									: paymentType}
						</PropertyDisplay>
						<PropertyDisplay label="Payment Transaction ID">
							{paymentTransID}
						</PropertyDisplay>
					</>
				)}
				{ }
				<PropertyDisplay label="Requested Delivery Date">
					{formatDate(getPossiblyInvalidValue(reqDeliveryDate), dateFormat.DATE)}
					{showErrors && <InvalidDataErrorDisplay
						subject="delivery date"
						value={reqDeliveryDate}
					/>}
				</PropertyDisplay>
				<PropertyDisplay label="Requested Activation Date">
					{formatDate(getPossiblyInvalidValue(reqActivationDate), dateFormat.DATE)}
					{showErrors && <InvalidDataErrorDisplay
						subject="activation date"
						value={reqActivationDate}
					/>}
				</PropertyDisplay>
				{codes_upload_date && [CODES_AVAILABLE, COMPLETE].includes(status || '') ? (
					<>
						{codes_downloaded_by_userName && codes_downloaded_date && (
							<PropertyDisplay label="Downloaded By">
								{`${codes_downloaded_by_userName} on ${formatDate(
									parseDateString(codes_downloaded_date),
									dateFormat.DATE,
								)}`}
							</PropertyDisplay>
						)}
						<PropertyDisplay label="Expiration Date">
							{formatDate(getExpirationDate(codes_upload_date), dateFormat.DATE)}
						</PropertyDisplay>
						<PropertyDisplay label="Days Remaining">
							{Math.max(
								dayjs(getExpirationDate(codes_upload_date)).diff(now(), 'days') + 1,
								0,
							)}
						</PropertyDisplay>
					</>
				) : null}
				{(order?.created_by_user_name || order?.created_by_company_name) && (
					<ActorDisplay
						name={order.created_by_user_name}
						company={order.created_by_company_name}
						label='Created By'
						date={order.createdDate}
						emailAddress={order.created_by_user_email}
						orderId={order?.publisherPO}
						showEmailAddressAsLink={linkSubmittersEmail}

					/>
				)}
				{(order?.submitted_by_user_name || order?.submitted_by_company_name) && (
					<ActorDisplay
						name={order.submitted_by_user_name}
						company={order.submitted_by_company_name}
						label='Submitted By'
						date={order.submittedDate}
						emailAddress={order.submitted_by_user_email}
						orderId={order?.publisherPO}
						showEmailAddressAsLink={linkSubmittersEmail}

					/>
				)}
				<PropertyDisplay label="Order Comments">{publisherComments}</PropertyDisplay>
			</div>
			<SectionTitle>Product</SectionTitle>
			<Table className="table-nin" style={{ borderBottom: 'none' }}>
				<thead>
					<tr>
						<th>Product</th>
						<th>Component</th>
						<th>Quantity</th>
						<th className="text-right" style={{ minWidth: '8em' }}>Est. Price</th>
						<th style={{ minWidth: '8rem', textAlign: 'right' }}>Est. Total Price</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{game_code && game_name ? (
								<>{`[${game_code}] ${game_name}`}</>
							) : productsComponentsAreLoading ? (
								<LoadingText />
							) : (
								'—'
							)}
							{showErrors && <InvalidDataErrorDisplay
								subject="product"
								value={productID}
							/>}
						</td>
						<td>
							{componentGameName
								? `[${getPossiblyInvalidValue(componentID)}] ${getPossiblyInvalidValue(componentGameName)}`
								: productsComponentsAreLoading
									? null
									: '—'}
							{showErrors && <InvalidDataErrorDisplay
								subject="component"
								value={componentID}
							/>}
						</td>
						<td>{quantity ? numberWithCommas(quantity) : '—'}</td>
						<td>
							<div className="text-right Step1OrderAndProducts__padded-cell">
								{pricesAreLoading
									? null
									: unitPrice
										? formatUnitPriceDisplay()
										: '—'}
							</div>

						</td>
						<td>
							<div className="d-flex Step1OrderAndProducts__padded-cell"
								style={{
									justifyContent: 'flex-end',
								}}>
								{pricesAreLoading
									? null
									: totalPrice
										? hasTotalPriceMismatch
											? <span className="text-danger">{formatCurrency(totalPrice)}<sup>&dagger;</sup></span>
											: formatCurrency(totalPrice)
										: '—'}
							</div>
						</td>
					</tr>
					{taxPercentDisplay && (
						<tr>
							<td colSpan={4}></td>
							<td
								className="Step1OrderAndProducts__total-cell position-relative"

							>
								<div className="d-flex Step1OrderAndProducts__total-heading">
									Tax ({taxPercentDisplay}):
								</div>
								&nbsp;
								{taxDisplay}
							</td>
						</tr>
					)}
					{grandTotalDisplay && (
						<tr>
							<td colSpan={4}></td>
							<td
								className="Step1OrderAndProducts__total-cell"
								style={{
									background: 'var(--nin-color-ltgray3)',
									fontWeight: 'bold',
								}}
							>
								<div className="d-flex Step1OrderAndProducts__total-heading">
									Estimated Total:
								</div>
								&nbsp;
								{grandTotalDisplay}
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			{(showErrors && (hasUnitPriceFeeMismatch || hasTotalPriceMismatch)) && (
				<div className="font-italic text-danger text-right">
					<small>
						<sup>&dagger;</sup>{' '}
						<i>Price changed since product was added to order</i>
					</small>
				</div>
			)}
			{(rejectComment || reviewerComment) && (
				<>
					<SectionTitle>Additional Comments</SectionTitle>
					{rejectComment && (
						<PropertyDisplay label="Comments on Rejection">
							{rejectComment}
						</PropertyDisplay>
					)}
					{reviewerComment && (
						<PropertyDisplay label="Comments to PO Approver">
							{reviewerComment}
						</PropertyDisplay>
					)}
				</>
			)}

		</>
	);
};
export default DigitalCodesOrder;
