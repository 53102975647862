import { PaginatedCall } from '../utils/serviceUtils';
import { orderConst } from '../constants/orderConstants';
import { subConst } from '../constants/submissionsConstants';
import api from './baseService';
import { PlatformNames } from '../types/constants';

const pollingUrlPath = '/pdb/executions/';
export interface FreightForwardersExternal {
	name: string;
	code: string;
	has_additional_fields: boolean;
	company_freight_forwarder: {
		default_flag: boolean;
		carrier_account_number: string;
		service_level: string;
		japanese_contact_name: string;
		japanese_contact_email: string;
		japanese_contact_phone_number: string;
	} | null;
}

export const getOrders: PaginatedCall = (count, limit, offset) =>
	api().get('/pdb/orders', { params: { count, limit, offset } });

export const getOrder = (orderHeaderId: string) =>
	api().get(`/pdb/orders/${orderHeaderId}`);

export const saveOrder = (payload: Record<string, any>) =>
	api().post('/pdb/order/create/save', payload);

export const putEditOrder = (orderHeaderId: string, payload: Record<string, any>) =>
	api().put(`/pdb/orders/${orderHeaderId}`, JSON.stringify(payload));

export const approveOrderHeader = (orderHeaderId: string) =>
	api().post(`/pdb/orders/${orderHeaderId}/approval`);

export const postOrderHeaderAction = (
	orderHeaderId: string,
	payload: {
		action: 'REVIEW' | 'APPROVE' | 'REJECT' | 'CANCEL' | 'SUBMIT';
		comment?: string;
	},
	callback?: () => void,
) =>
	api().post<number>(`/pdb/physicalorders/${orderHeaderId}/actions`, JSON.stringify(payload), {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
		pollingCallback: callback,
	});

interface OrderOldDetailsData {
	order_header_id: number,
	publisher_po_number: string,
	order_creation_date: string,
	order_detail_id: number,
	release_version: string,
	submission_version: string,
	submission_status: ValuesOf<typeof subConst.STATUS>;
	quantity: number;
	unit_price: number;
	ship_date: string;
	noa_po_number?: string;
	noa_sales_order_number?: string;
}
export const getOldOrderDetails = (productId: string) =>
	api().get<OrderOldDetailsData[]>('/pdb/orders/details', { params: { product_id: productId }});

export const postOrderDetails = (payload: Record<string, any>) =>
	api().post('/pdb/order/detail', payload);

export const deleteOrder = (orderHeaderId: string) =>
	api().delete(`/pdb/orders/${orderHeaderId}`);

export const getPrice = (productId: string, orderHeaderId: string, quantity: string) =>
	api().get<{ product_id: string; price: string; }>(`/pdb/order_prices?product_id=${productId}&order_header_id=${orderHeaderId}&quantity=${quantity}`);

export const getOrderableProducts = (companyId: string) =>
	api().get(`/pdb/products?orderable=True&ndid_company_id=${companyId}`);

type OrderableDigitalProductData = Pick<
	ProductData,
	| 'active_rights_transfer'
	| 'agreement_family'
	| 'confidential_flag'
	| 'demo_only'
	| 'game_code'
	| 'game_name'
	| 'platform_code'
	| 'platform_name'
	| 'product_id'
	| 'product_release_date'
	| 'system_family'
>;
export const getDigitalOrderableProducts = (ndid_company_id: string) =>
	api().get<OrderableDigitalProductData[]>('/pdb/products', { params: { 'digital_orderable': 'True', ndid_company_id}});

interface FreightForwarders {
	code: string;
	name: string;
	status: string,
	has_additional_fields: boolean;
}
export const getFreightForwardersList = () =>
	api().get<FreightForwarders[]>('/pdb/physicalorders/freightforwarders');

export const getFreightForwarder = (code: string) =>
	api().get<FreightForwarders>(`/pdb/physicalorders/freightforwarders/${code}`);

export const createFreightForwarder = (data: Record<string, any>) =>
	api().post('/pdb/physicalorders/freightforwarders', data);

export const updateFreightForwarder = (code: string, data: Record<string, any>) =>
	api().put(`/pdb/physicalorders/freightforwarders/${code}`, data);

export const getPackoutFacilitiesList = (mode: string) =>
	api().get(`/pdb/companies/packout_facilities/?mode=${mode}`);

interface PackoutFacilitiesData {
	packout_facility_id: number,
	status: string;
	name: string;
	use_type: string;
	phone: string;
	address_1: string;
	address_2: string | null;
	city: string;
	state: string;
	postal_code: string;
	country_name: string;
	country_code: string;
}
export const getAssociatedPackoutFacilities = (companyId: string) =>
	api().get<PackoutFacilitiesData[]>(`/pdb/companies/${companyId}/packout_facilities`);

export const createPackoutFacility = (data: Record<string, any>) =>
	api().post('/pdb/physicalorders/packout_facilities', data);

export const postPackoutFacilityAssociation = (facilityId: string) =>
	api().post(`/pdb/physicalorders/packout_facilities/${facilityId}/associations`);

export const deletePackoutFacilityAssociation = (facilityId: string) =>
	api().delete(`/pdb/physicalorders/packout_facilities/${facilityId}/associations`);

export const updatePackoutFacility = (facilityId: string, data: Record<string, any>) =>
	api().put(`/pdb/physicalorders/packout_facilities/${facilityId}`, data);

export const getOrderDetailShipmentInfo = (orderDetailId: string) =>
	api().get(`/pdb/orders/details/${orderDetailId}/shipping`);

export const getCreateOrderPageResources = () =>
	api().get('/pdb/order/create/create_order_page_resources');

interface FreightForwarderAssociationsData {
	name: string;
	code: string;
	status: string;
	company_freight_forwarder: {
		carrier_account_number?: string;
		default_flag: boolean;
		japanese_contact_email?: string;
		japanese_contact_name?: string;
		japanese_contact_phone_number?: string;
		service_level?: string;
	}
}
export const getFreightForwarderAssociations = () =>
	api().get<FreightForwarderAssociationsData[]>('/pdb/physicalorders/freightforwarders/associations');

export interface PhysicalOrderIndexData {
	order_header_id: number;
	order_status: ValuesOf<typeof orderConst.STATUS>;
	publisher: string;
	platform: string;
	submission_date: string;
	publisher_po: string;
	total_price: string;
	sales_flow_version: number;
	noa_sales_order_number: string | null,
	req_number: string | null,
	modification_date: string,
	noa_po: string | null,
	has_error: 0 | 1
}
export const getPhysicalOrders: PaginatedCall<PhysicalOrderIndexData> = (count, limit, offset, otherParams) =>
	api().get('/pdb/physicalorders', { params: { count, limit, offset, ...otherParams } });

export interface ConsigneeData {
	address_id: number;
	city: string;
	consignee_company_id: number;
	country_code: string;
	country_name: string;
	default_flag: boolean;
	name: string;
	postal_code: string;
	region: string;
	street_line_1: string;
	street_line_2: string;
}
export const getConsignees = () =>
	api().get<ConsigneeData[]>('/pdb/physicalorders/consignees');

export const postFreightForwarderAssociation = (code: string, payload: Record<string, any>) =>
	api().post(`/pdb/physicalorders/freightforwarders/${code}/associations`, payload);

export const deleteFreightForwarderAssociation = (code: string) =>
	api().delete(`/pdb/physicalorders/freightforwarders/${code}/associations`);

export const getFreightForwardersListExternal = () =>
	api().get<FreightForwardersExternal[]>('/pdb/physicalorders/freightforwarders');

export interface OrderDetailData {
	company_name: string;
	consignee_company_id: number | null | InvalidData<number>,
	created_by_company_name: string;
	created_by_ndid: string;
	created_by_user_name: string;
	creation_date: string;
	credit_hold_flag: boolean,
	edi_order_flag: boolean,
	freight_forwarder_code: string | null | InvalidData<string>,
	modification_date: string;
	modified_by_company_name: string;
	modified_by_ndid: string;
	modified_by_user_name: string;
	ndid_company_id: string;
	noa_po_number: string;
	noa_sales_order_number: string;
	order_header_id: number,
	order_status: ValuesOf<typeof orderConst.STATUS>;
	packout_facility_contact_email: string | null,
	packout_facility_contact_name: string | null;
	packout_facility_contact_phone_number: string | null;
	packout_facility_id: number | null | InvalidData<number>,
	platform_code: PlatformCode;
	platform_name: string;
	port_of_entry_code: string | null | InvalidData<string>,
	publisher_po_number: string;
	requisition_number: string;
	retail_order_management_users: {
		email_address: string;
		ndid_user_id: string;
	}[];
	sales_flow_version: number;
	order_status_history: {
		status: ValuesOf<typeof orderConst.STATUS>;
		status_change_date: string;
		changed_by_ndid_user_id: string;
		changed_by_user_name: string;
		changed_by_ndid_company_id: string;
		changed_by_company_name: string
	}[];
	order_comments: Partial<Record<ValuesOf<typeof orderConst.COMMENT>, {
		comment: string;
		creation_date: string;
		ndid_user_id: string;
	}>>,
	order_errors: string[],
	order_details: {
		order_detail_id: number,
		product_id: number | InvalidData<number>,
		line_number: number,
		noa_part_number: string;
		publisher_part_number: string;
		status: ValuesOf<typeof orderConst.DETAIL_ITEM_STATUS>;
		quantity: number | null | InvalidData<number>,
		unit_price: string;
		requested_ship_date: string | null |InvalidData<string>,
		submission_id: number | null | InvalidData<number>,
		submission_version: ValuesOf<typeof subConst.STATUS>;
		submission_status: string;
		card_size: string;
		product_release_version: string;
		product_name: string;
		game_code: string;
		platform_code: string;
		initial_order: boolean,
		shipments: {
			quantity: number,
			ship_date: string
		}[];
		standard_rom_price: string;
	}[],
	shipping_info: {
		consignee_address_city: string;
		consignee_address_country_code: string;
		consignee_address_country_name: string;
		consignee_address_postal_code: string;
		consignee_address_region: string;
		consignee_address_street_line_1: string;
		consignee_address_street_line_2: string;
		consignee_company_name: string;
		freight_forwarder_carrier_account_number: string;
		freight_forwarder_name: string,
		freight_forwarder_service_level: string;
		japanese_contact_email: string;
		japanese_contact_name: string;
		japanese_contact_phone_number: string;
		packout_facility_address_city: string;
		packout_facility_address_country_code: string;
		packout_facility_address_country_name: string;
		packout_facility_address_postal_code: string;
		packout_facility_address_region: string;
		packout_facility_address_street_line_1: string;
		packout_facility_address_street_line_2: string;
		packout_facility_name: string;
		packout_facility_phone_number: string;
		port_of_entry_name: string;
	}
}
export const getPhysicalOrder = (orderHeaderId: string) =>
	api().get<OrderDetailData>(`/pdb/physicalorders/${orderHeaderId}`);

export const deletePhysicalOrder = (orderHeaderId: string) =>
	api().delete(`/pdb/physicalorders/${orderHeaderId}`);

interface OrderDetailsData {
	order_header_id: number;
	order_detail_id: number;
	order_status: ValuesOf<typeof orderConst.STATUS>;
	ndid_company_id: string;
	quantity: number;
	unit_price: number;
	release_version: string;
	submission_version: string;
	company_name: string;
	ship_date: string;

	publisher_po_number?: string;
	noa_po_number?: string;
	noa_sales_order_number?: string;
}
export const getOrderDetails = (productId: string) =>
	api().get<OrderDetailsData[]>('/pdb/physicalorders/details', { params: { product_id: productId }});

interface CountriesData {
	country_code: string;
	country_name: string;
}
export const getCountries = () =>
	api().get<CountriesData[]>('/pdb/countries');

export const postConsignees = (payload: Record<string, any>) =>
	api().post('/pdb/physicalorders/consignees', payload);

export const putFreightForwarderAssociation = (code: string, payload: Record<string, any>) =>
	api().put(`/pdb/physicalorders/freightforwarders/${code}/associations`, payload);

export const deleteConsignee = (consigneeId: string) =>
	api().delete(`/pdb/physicalorders/consignees/${consigneeId}`);

export const putConsignee = (consigneeId: string, payload: Record<string, any>) =>
	api().put(`/pdb/physicalorders/consignees/${consigneeId}`, payload);

interface NewOrderResourcesData {
	platforms: {
		platform_code: PlatformCode;
		platform_name: PlatformNames;
		lead_time_days_count: number;
		initial_order_minimum_qty: number;
		order_minimum_qty: number;
		order_increment_qty: number;
		standard_rom_prices: Record<string, string> | null;
	}[];
	publisher_po_numbers: string[];
}
export const getNewOrderResources = () =>
	api().get<NewOrderResourcesData>('/pdb/physicalorders/details/resources');

interface OrderablePhysicalProductData {
	product_id: number;
	platform_code: string;
	game_code: string;
	game_name: string;
	initial_order: boolean;
	submissions: {
		product_submission_id: number;
		release_version: string;
		submission_version: string;
		card_size: string;
		submission_status: string;
	}[];
	part_numbers: {
		part_number: string;
		part_number_owner: 'PUBLISHER' | 'NOA' | 'NCL';
	}[];
}
export const getNewOrderProducts = (platformCode: string) =>
	api().get<OrderablePhysicalProductData[]>(`/pdb/physicalorders/platforms/${platformCode}/products`);

export const getNewOrderProductPrice = (productId: string) =>
	api().get<string | unknown>(`/pdb/physicalorders/products/${productId}/price`, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
	});

interface ShippingInfoData {
	physical_order: Pick<OrderDetailData,
		| 'order_header_id'
		| 'order_status'
		| 'ndid_company_id'
		| 'platform_code'
		| 'order_comments'
		| 'packout_facility_id'
		| 'consignee_company_id'
		| 'freight_forwarder_code'
		| 'port_of_entry_code'
		| 'packout_facility_contact_name'
		| 'packout_facility_contact_phone_number'
		| 'packout_facility_contact_email'
	> & {
		self_consigned?: boolean | null;
	};
	ports_of_entry: {
		port_of_entry_code: string;
		port_of_entry_name: string;
	}[];
	freight_forwarders: {
		name: string;
		code: string;
		status: string;
		has_additional_fields: false;
		company_freight_forwarder: {
			default_flag: boolean;
			carrier_account_number: string | null;
			service_level: string | null;
			japanese_contact_name: string | null;
			japanese_contact_email: string | null;
			japanese_contact_phone_number: string | null;
		};
	}[];
	packout_facilities: {
		packout_facility_id: number;
		name: string;
		phone_number: string;
		street_line_1: string;
		street_line_2: string | null;
		city: string;
		region: string;
		postal_code: string;
		country_code: string;
		country_name: string;
	}[];
	consignees: {
		consignee_company_id: number;
		name: string;
		default_flag: boolean;
		address_id: number;
		street_line_1: string;
		street_line_2: string | null;
		city: string;
		region: string;
		postal_code: string;
		country_code: string;
		country_name: string;
	}[];
}

export const getShippingInfo = (orderHeaderId: string) =>
	api().get<ShippingInfoData>(`/pdb/physicalorders/${orderHeaderId}/shipping`);

interface OrderDetailsPayload {
	action: string;
	platform_code: string;
	publisher_po_number: string;
	comments?: string;
	details?: {
		product_id?: number;
		submission_id?: number;
		quantity?: number;
		requested_ship_date?: any;
	}[];
}
export const postNewOrderDetails = (payload: OrderDetailsPayload) =>
	api().post<number>('/pdb/physicalorders', payload, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
	});

interface OrderInfoDetailsData extends NewOrderResourcesData {
	products: OrderablePhysicalProductData[];
	physical_order: Pick<OrderDetailData,
		| 'order_header_id'
		| 'edi_order_flag'
		| 'platform_code'
		| 'platform_name'
		| 'publisher_po_number'
		| 'order_status'
		| 'ndid_company_id'
		| 'company_name'
		| 'order_comments'
		| 'order_details'
	> & {
		sales_flow_version: number;
	};
}
export const getOrderInfoAndResources = (orderHeaderId: string) =>
	api().get<OrderInfoDetailsData>(`/pdb/physicalorders/${orderHeaderId}/info_details`);

export const putOrderInfoDetails = (orderHeaderId: string, payload: OrderDetailsPayload) =>
	api().put<number>(`/pdb/physicalorders/${orderHeaderId}/info_details`, payload, {
		pollingUrl: pollingUrlPath,
		pollingTimeLimit: 120000,
	});

export interface OrderShippingPayload {
	action: string | null;
	port_of_entry_code: string | null;
	freight_forwarder_code: string | null;
	packout_facility_id: number | null;
	consignee_company_id: number | null;
	self_consigned: boolean;
	shipping_instructions: string | null;
	packout_facility_contact_name: string | null;
	packout_facility_contact_phone_number: string | null;
	packout_facility_contact_email: string | null;
}
export const putSaveOrderShippingDetails = (orderHeaderId: string, payload: OrderShippingPayload) =>
	api().put<number>(`/pdb/physicalorders/${orderHeaderId}/shipping`, payload);

export const getOrdercomments = (orderHeaderId: string) =>
	api().get(`/pdb/physicalorders/${orderHeaderId}/comments`);

export const putOrderComments = (
	orderHeaderId: string,
	payload: {
		REVIEWER?: string | null;
		NOTES_TO_NCL?: string | null;
	},
) => api().put(`/pdb/physicalorders/${orderHeaderId}/comments`, payload);
