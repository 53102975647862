import React from 'react';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { useUserProfile } from '../../../hooks/reduxHooks';
import { filterInternalOnly } from '../CompanyProfile.helpers';
import RenderAdditionalInfo from './RenderAdditionalInfo';
import RenderCompanyInfo from './RenderCompanyInfo';
import CustomFieldEntry from '../../../components/CustomField/CustomFieldEntry';
import { isAuthorized } from '../../../utils/userUtils';
import { permConst } from '../../../constants/permConst';

const CompanyInfoTab = ({ companyData, customFields }) => {
	const userProfile = useUserProfile();
	const canViewCustomFields = isAuthorized(userProfile.permissions,
		[permConst.CUSTOM_FIELD.VIEW.ALL]);
	const sortedCustomFields = customFields ? [...customFields].sort((a, b) =>
		(a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) : [];

	return (
		<>
			<SectionTitle>Company Information</SectionTitle>
			<RenderCompanyInfo
				companyData={companyData}
			/>
			<SectionTitle>
				Additional
			</SectionTitle>
			<RenderAdditionalInfo
				companyData={companyData}
				userProfile={userProfile}
			/>
			{filterInternalOnly(
				companyData,
				userProfile,
				'internal-notes'
			)}
			{canViewCustomFields && (
				<SectionTitle>
					Custom Fields <span className='text-danger'>(Internal Only)</span>
				</SectionTitle>
			)}
			{canViewCustomFields &&
				sortedCustomFields?.map((customField) => <CustomFieldEntry {...customField} />)
			}
		</>
	);
};
export default CompanyInfoTab;
