import React from 'react';
import { Link } from 'react-router-dom';

import { linkColumn } from '../../components/BaseTable/BaseTable';
import FAIcon from '../../components/FAIcon/FAIcon';
import { digitalCodesConstants } from '../../constants/digitalCodesConstants';
import { platformCodes } from '../../constants/platformConstants';
import { formatCurrency } from '../../utils/currencyUtils';
import { dateFormat, formatDate, parseDateString } from '../../utils/dateUtils';
import { displayStringForStatus, variantForDigitalCodesStatus } from '../../utils/digitalCodesUtils';


const formatDateField = (value) => {
	return formatDate(parseDateString(value), dateFormat.DATE);
};

const {
	DRAFT, READY_FOR_REVIEW, READY_FOR_APPROVAL, REJECTED, CANCELED, AWAITING_PAYMENT_INFO,
	PAYMENT_PENDING, PAYMENT_RECEIVED, UPLOAD_IN_PROGRESS, UPLOAD_FAILED, CODES_AVAILABLE,
	DOWNLOAD_EXPIRED, COMPLETE
} = digitalCodesConstants.status;

const READY_FOR_ACTIVATION = 'Ready For Activation';
const ACTIVE = 'Active';
const INACTIVE = 'Inactive';

export const getFiltersConfig = ({isReviewer, isApprover, isActivator, purposesData}) => {
	const filterProperties = new Map();

	const commonStatuses = [
		DRAFT,
		READY_FOR_REVIEW,
		READY_FOR_APPROVAL,
		REJECTED,
		CANCELED,
		AWAITING_PAYMENT_INFO,
		PAYMENT_PENDING,
		PAYMENT_RECEIVED,
		CODES_AVAILABLE,
		DOWNLOAD_EXPIRED,
		COMPLETE,
	];
	const internalOnlyStatuses = [UPLOAD_IN_PROGRESS, UPLOAD_FAILED];

	const selectableStatus =
		isApprover || isReviewer ? [...commonStatuses, ...internalOnlyStatuses] : commonStatuses;

	const defaultSelectedStatus = isApprover
		? [READY_FOR_APPROVAL, CODES_AVAILABLE, COMPLETE]
		: isReviewer
			? [
				READY_FOR_REVIEW,
				PAYMENT_PENDING,
				PAYMENT_RECEIVED,
				UPLOAD_IN_PROGRESS,
				UPLOAD_FAILED,
				CODES_AVAILABLE,
				COMPLETE,
		  ]
			: [
				DRAFT,
				READY_FOR_REVIEW,
				READY_FOR_APPROVAL,
				AWAITING_PAYMENT_INFO,
				PAYMENT_PENDING,
				PAYMENT_RECEIVED,
				CODES_AVAILABLE,
		  ];

	filterProperties.set('Status', {
		filter: 'status',
		selectedFilters: new Set(defaultSelectedStatus.map(status => displayStringForStatus(status))),
		selectableFilters: new Set(selectableStatus.map(status => displayStringForStatus(status))),
		customEvaluation: (status, filters) => filters.has(displayStringForStatus(status)),
	});

	const selectablePurposes = purposesData &&
		purposesData.find(set => set.platform === platformCodes['Nintendo Switch'])
			?.digital_orders_purpose
			?.map(item => item.purpose);

	if (selectablePurposes) {
		filterProperties.set('Purpose', {
			filter: 'purpose',
			selectableFilters: new Set(selectablePurposes),
			customEvaluation: (purpose, filters) => filters.has(purpose)
		});
	}

	if (isActivator) {
		filterProperties.set('Code Status', {
			filter: 'code_activation',
			selectableFilters: new Set([READY_FOR_ACTIVATION, ACTIVE, INACTIVE]),
		});
	}

	return filterProperties;
};

export const getTableFormat = ({ canCreateOrder, canViewCompanies, companies, invalidAgreement }) => {
	const DateCell = ({ value }) => {
		if (!value) { return null; }
		return <span>{formatDateField(value)}</span>;
	};
	const StatusCell = ({ value }) => {
		return (
			<div>
				<strong className={`text-${variantForDigitalCodesStatus(value)}`}>
                    {displayStringForStatus(value).toUpperCase()}
				</strong>
			</div>
		);
	};
	return [
		{
			Header: 'Submitted',
			accessor: 'submittedDate',
			width: 110,
			Cell: DateCell,
		},
		{
			Header: 'Product',
			accessor: 'game_name',
			Cell: ({ original }) => 
				`${original.product?.['game_code'] ? (`[${original.product?.['game_code']}] `) : ''}${(original.product?.['game_name'] ? original.product?.['game_name'] : '—')}`
		},
		{
			Header: 'Purpose',
			accessor: 'purpose',
		},
		{
			Header: 'Publisher PO',
			accessor: 'publisherPO',
		},
		...(canViewCompanies
			? [
				{
					Header: 'Publisher',
					accessor: 'NDID',
					Cell: function PublisherCell({ value }) {
						return (
							(companies.find((company) => company.ndid_company_id === value) || {})[
								'company_name'
							] || null
						);
					},
				},
			  ]
			: []),
		{
			Header: 'Requested Delivery',
			accessor: 'reqDeliveryDate',
			width: 110,
			Cell: DateCell,
		},
		{
			Header: 'Requested Activation',
			accessor: 'reqActivationDate',
			width: 110,
			Cell: DateCell,
		},
		{
			Header: 'Total Price',
			id: 'totalPrice',
			accessor: (row) => Number(row.totalPrice),
			Cell: function PriceCell({ value }) {
				if (!value) { return null; }
				return <span>{formatCurrency(value)}</span>;
			},
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: StatusCell,
		},
		{
			...linkColumn,
			Cell: function LinkCell({ original }) {
				if (original.status === digitalCodesConstants.status.DRAFT && canCreateOrder && !invalidAgreement) {
					return (
						<Link to={`/orders/digital-codes/${original.orderID}/create`}>
							<FAIcon name="chevron-right" />
						</Link>
					);
				}
				return (
					<Link to={`/orders/digital-codes/${original.orderID}`}>
						<FAIcon name="chevron-right" />
					</Link>
				);
			},
		},
	];
};

export const getActivationStatus = ({ activatable, activation_date }) => {
	if (activation_date)
		return ACTIVE;
	if (activatable)
		return READY_FOR_ACTIVATION;
	return INACTIVE;
};
