import { AnySchema } from 'yup';

/**
 * Validates if a string is a valid product code. Returns a upper-cased
 * formatted code if valid, false if not.
 * @param {string} code - Product code to be validated.
 */
export const validateProductCode = (code: string) => {
	if (typeof code === 'string' && code.length <= 13 && /(^$|^[A-Za-z0-9-]+$)/.test(code)) {
		return code.toUpperCase();
	}
	return false;
};

/**
 * Validates if a hash and its values conform with a given Yup schema. 
 * @param {object} schema - Yup schema object
 * @param {object} values - Values hash
 * @param {object} context - object with context values
 */
export const validateToSchema = (
	schema: AnySchema,
	values?: Record<string, any> | null,
	context?: Record<string, any>,
): Record<string, string> => {
	if (values) {
		try {
			schema.validateSync(schema.cast(values, context ? { context } : undefined), {
				abortEarly: false,
				context,
			});
		} catch (err: unknown) {
			if (!!err && typeof err === 'object' && 'inner' in err && Array.isArray(err.inner)) {
				return err.inner.reduce(
					(r: Record<string, any>, e: { path: string; message: string }) =>
						(r[e.path] = e.message) && r,
					{},
				) || {};
			}
			throw err;
		}
	}
	return {};
};
