export type PermissionNode = string | Record<string, any>;
export const permConst = {
	ANNOUNCEMENT: {
		ADD: {
			ALL: 'announcement.add.all',
		} as const,
		DELETE: {
			ALL: 'announcement.delete.all',
		} as const,
		EDIT: {
			ALL: 'announcement.edit.all',
		} as const,
		VIEW: {
			ALL: 'announcement.view.all',
		} as const,
	} as const,
	COMPANY: {
		MAKER_CODE: {
			EDIT: {
				ALL: 'company.makercode.edit.all',
			} as const,
		} as const,
		PROJECTABILITY: {
			EDIT: {
				ALL: 'company.projectability.edit.all',
			} as const,
		} as const,
		NOTE: {
			EDIT: {
				ALL: 'company.note.edit.all',
			} as const,
		} as const,
		PACKOUT_FACILITY: {
			EDIT: {
				COMPANY: 'company.packoutfacility.edit.company',
			} as const,
			VIEW: {
				ALL: 'company.packoutfacility.view.all',
				COMPANY: 'company.packoutfacility.view.company',
			} as const,
		} as const,
		PART_SKU: {
			EDIT: {
				COMPANY: 'company.partsku.edit.company',
			} as const,
		} as const,
		VIEW: {
			ALL: 'company.view.all',
			COMPANY: 'company.view.company',
		} as const,
	} as const,
	CONSIGNEE: {
		EDIT: {
			COMPANY: 'consignee.edit.company',
		} as const,
		VIEW: {
			COMPANY: 'consignee.view.company',
		} as const,
	} as const,
	CUSTOM_FIELD: {
		VIEW: {
			ALL: 'custom_field.view.all',
		},
	} as const,
	CUSTOM_FIELD_DEFINITION: {
		EDIT: {
			ALL: 'custom_field_definition.edit.all',
		} as const,
		VIEW: {
			ALL: 'custom_field_definition.view.all',
		},
	} as const,
	CUSTOMER_FEEDBACK: {
		SUBMIT: {
			COMPANY: 'customer_feedback.submit.company',
		} as const,
	} as const,
	DIGITAL_ORDER: {
		APPROVE: {
			ALL: 'digitalorder.approve.all',
		} as const,
		APPROVER_COMMENTS: {
			VIEW: {
				ALL: 'digitalorder.approver_comments.view.all',
			} as const,
		} as const,
		COMPLETE: {
			ALL: 'digitalorder.complete.all',
		} as const,
		CODES: {
			ACTIVATE: {
				ALL: 'digitalorder.codes.activate.all',
			} as const,
			RETRACT: {
				ALL: 'digitalorder.codes.retract.all',
			} as const,
			SEND_REMINDER: {
				ALL: 'digitalorder.codes.send_reminder.all',
			} as const,
			UPLOAD: {
				ALL: 'digitalorder.codes.upload.all',
			} as const,
			VIEW: {
				COMPANY: 'digitalorder.codes.view.company',
			} as const,
		} as const,
		INVOICE: {
			VIEW: {
				ALL: 'digitalorder.invoice.view.all',
				COMPANY: 'digitalorder.invoice.view.company',
			},
		} as const,
		PAYMENT: {
			SUBMIT: {
				COMPANY: 'digitalorder.payment.submit.company',
			},
		} as const,
		PAYPAL: {
			ORDER_API: {
				COMPANY: 'digitalorder.paypal.order_api.company',
			}
		},
		PUBLISHER_COMMENTS: {
			VIEW: {
				ALL: 'digitalorder.publisher_comments.view.all',
			} as const,
		} as const,
		REVIEW: {
			ALL: 'digitalorder.review.all',
		} as const,
		SUBMIT: {
			COMPANY: 'digitalorder.submit.company',
		} as const,
		VIEW: {
			ALL: 'digitalorder.view.all',
			COMPANY: 'digitalorder.view.company',
		} as const,
		SALES_FLOW_2: {
			COMPANY: 'digitalorder.sales_flow_2.company',
		} as const,
	} as const,
	FEATURES: {
		EDIT: {
			ALL: 'features.edit.all',
		} as const,
	} as const,
	FEATURE_FLAG: {
		VIEW: {
			ALL: 'featureflag.view.all',
		} as const,
		EDIT: {
			ALL: 'featureflag.edit.all',
		},
	} as const,
	FILE: {
		UPLOAD: {
			ANY: 'file.upload.any',
		} as const,
	} as const,
	FREIGHT_FORWARDER: {
		ADD: {
			ALL: 'freightforwarder.add.all',
		} as const,
		EDIT: {
			ALL: 'freightforwarder.edit.all',
			COMPANY: 'freightforwarder.edit.company',
		} as const,
		VIEW: {
			ALL: 'freightforwarder.view.all',
			COMPANY: 'freightforwarder.view.company',
		} as const,
	} as const,
	HELP: {
		VIEW: {
			ALL: 'help.view.all',
		} as const,
		EDIT: {
			ALL: 'help.edit.all',
		},
	} as const,
	MARKETING_EVENT: {
		ACTIVATION: {
			EDIT: {
				MANAGER: 'marketingevent.activation.edit.manager',
			} as const,
			VIEW: {
				MANAGER: 'marketingevent.activation.view.manager',
			} as const,
		} as const,
		ADD: {
			ALL: 'marketingevent.add.all',
		} as const,
		DELETE: {
			MANAGER: 'marketingevent.delete.manager',
		} as const,
		DETAILS: {
			EDIT: {
				MANAGER: 'marketingevent.details.edit.manager',
			} as const,
			VIEW: {
				ALL: 'marketingevent.details.view.all',
				COMPANY: 'marketingevent.details.view.company',
				COMPANY_SUPPORT: 'marketingevent.details.view.company.support',
				COORDINATOR: 'marketingevent.details.view.coordinator',
				INTERNAL: 'marketingevent.details.view.internal',
				MANAGER: 'marketingevent.details.view.manager',
			} as const,
		} as const,
		EDIT: {
			MANAGER: 'marketingevent.edit.manager',
		} as const,
		MANAGER: {
			EDIT: {
				MANAGER: 'marketingevent.manager.edit.manager',
				ALL: 'marketingevent.manager.edit.all',
			} as const,
		} as const,
		PRODUCT: {
			ACTIVATION: {
				VIEW: {
					MANAGER: 'marketingevent.product.activation.view.manager',
				} as const,
			} as const,
			ASSET: {
				ACTIVATION: {
					EDIT: {
						MANAGER: 'marketingevent.product.asset.activation.edit.manager',
					} as const,
					VIEW: {
						MANAGER: 'marketingevent.product.asset.activation.view.manager',
					} as const,
				} as const,
				ADD: {
					MANAGER: 'marketingevent.product.asset.add.manager',
					COORDINATOR: 'marketingevent.product.asset.add.coordinator',
				} as const,
				EDIT: {
					MANAGER: 'marketingevent.product.asset.edit.manager',
					COORDINATOR: 'marketingevent.product.asset.edit.coordinator',
				} as const,
				DELETE: {
					MANAGER: 'marketingevent.product.asset.delete.manager',
					COORDINATOR: 'marketingevent.product.asset.delete.coordinator',
				} as const,
				UPLOAD: {
					COMPANY: 'marketingevent.product.asset.upload.company',
					COMPANY_SUPPORT: 'marketingevent.product.asset.upload.company.support',
				} as const,
				REVIEW: {
					MANAGER: 'marketingevent.product.asset.review.manager',
					COORDINATOR: 'marketingevent.product.asset.review.coordinator',
				} as const,
				VIEW: {
					MANAGER: 'marketingevent.product.asset.view.manager',
					COORDINATOR: 'marketingevent.product.asset.view.coordinator',
					COMPANY: 'marketingevent.product.asset.view.company',
					COMPANY_SUPPORT: 'marketingevent.product.asset.view.company.support',
				} as const,
			} as const,
			ADD: {
				MANAGER: 'marketingevent.product.add.manager',
			} as const,
			EDIT: {
				MANAGER: 'marketingevent.product.edit.manager',
			} as const,
			VIEW: {
				INTERNAL: 'marketingevent.product.view.internal',
				MANAGER: 'marketingevent.product.view.manager',
				COORDINATOR: 'marketingevent.product.view.coordinator',
				COMPANY: 'marketingevent.product.view.company',
			} as const,
		} as const,
		REPORT: {
			VIEW: {
				MANAGER: 'marketingevent.report.view.manager',
				COORDINATOR: 'marketingevent.report.view.coordinator',
				COMPANY: 'marketingevent.report.view.company',
				COMPANY_SUPPORT: 'marketingevent.report.view.company.support',
			} as const,
		} as const,
		SUPPORT: {
			EDIT: {
				COMPANY: 'marketingevent.support.edit.company',
			} as const,
			VIEW: {
				COMPANY: 'marketingevent.support.view.company',
			} as const,
		} as const,
		TASK: {
			ACTIVATION: {
				EDIT: {
					MANAGER: 'marketingevent.task.activation.edit.manager',
				} as const,
				VIEW: {
					MANAGER: 'marketingevent.task.activation.view.manager',
				} as const,
			} as const,
			ADD: {
				MANAGER: 'marketingevent.task.add.manager',
			} as const,
			ASSIGN: {
				MANAGER: 'marketingevent.task.assign.manager',
				COMPANY: 'marketingevent.task.assign.company',
			} as const,
			CLAIM: {
				COORDINATOR: 'marketingevent.task.claim.coordinator',
				COMPANY_SUPPORT: 'marketingevent.task.claim.company.support',
			} as const,
			DELETE: {
				MANAGER: 'marketingevent.task.delete.manager',
			} as const,
			DETAIL: {
				EDIT: {
					MANAGER: 'marketingevent.task.detail.edit.manager',
					ASSIGNED: 'marketingevent.task.detail.edit.assigned',
				},
			} as const,
			PUBLISHER: {
				EDIT: {
					MANAGER: 'marketingevent.task.publisher.edit.manager',
				} as const,
				VIEW: {
					MANAGER: 'marketingevent.task.publisher.view.manager',
					COORDINATOR: 'marketingevent.task.publisher.view.coordinator',
				} as const,
			} as const,
			TYPE: {
				VIEW: {
					MANAGER: 'marketingevent.task.type.view.manager',
					COORDINATOR: 'marketingevent.task.type.view.coordinator',
				} as const,
			} as const,
			UNASSIGNED: {
				VIEW: { MANAGER: 'marketingevent.task.unassigned.view.manager' } as const,
			} as const,
			VIEW: {
				MANAGER: 'marketingevent.task.view.manager',
				COORDINATOR: 'marketingevent.task.view.coordinator',
				COMPANY: 'marketingevent.task.view.company',
				COMPANY_SUPPORT: 'marketingevent.task.view.company.support',
			} as const,
			WORK: {
				EDIT: {
					ASSIGNED: 'marketingevent.task.work.edit.assigned',
					COMPANY: 'marketingevent.task.work.edit.company',
					MANAGER: 'marketingevent.task.work.edit.manager',
				},
			},
		} as const,
		VIEW: {
			ALL: 'marketingevent.view.all',
			COMPANY: 'marketingevent.view.company',
			COORDINATOR: 'marketingevent.view.coordinator',
			INTERNAL: 'marketingevent.view.internal',
			MANAGER: 'marketingevent.view.manager',
			COMPANY_SUPPORT: 'marketingevent.view.company.support',
		} as const,
		VISIBILITY: {
			EDIT: {
				MANAGER: 'marketingevent.visibility.edit.manager',
			} as const,
			VIEW: {
				MANAGER: 'marketingevent.visibility.view.manager',
				COORDINATOR: 'marketingevent.visibility.view.coordinator',
			} as const,
		} as const,
	} as const,
	NCL: {
		LABEL_QUEUE: {
			EDIT: 'ncl.labelqueue.edit',
			VIEW: 'ncl.labelqueue.view',
		},
		USER_MANAGEMENT: {
			ADD: {
				ALL: 'ncl.usermgmt.add.all',
			},
		},
	} as const,
	ORDER: {
		CREATE: {
			ALL: 'order.create.all',
			COMPANY: 'order.create.company',
		} as const,
		DELETE: {
			ALL: 'order.delete.all',
			COMPANY: 'order.delete.company',
		} as const,
		DETAIL: {
			PRODUCT: {
				VIEW: {
					ALL: 'order.detail.product.view.all',
					COMPANY: 'order.detail.product.view.company',
				} as const,
			} as const,
		} as const,
		EDIT: {
			ALL: {
				DRAFT: 'order.edit.all.status.draft',
				SUBMITTED_FOR_APPROVAL: 'order.edit.all.status.submitted_for_approval',
				SUBMITTED_FOR_REVIEW: 'order.edit.all.status.submitted_for_review',
			} as const,
			COMPANY: 'order.edit.company',
			STATUS: {
				DRAFT: 'order.edit.status.draft',
				ORDER_APPROVED: 'order.edit.status.order_approved',
				REJECTED: 'order.edit.status.rejected',
				SUBMITTED_FOR_APPROVAL: 'order.edit.status.submitted_for_approval',
				SUBMITTED_FOR_REVIEW: 'order.edit.status.submitted_for_review',
				CANCELLED: 'order.edit.status.cancelled',
			} as const,
		} as const,
		VIEW: {
			ALL: 'order.view.all',
			COMPANY: 'order.view.company',
		} as const,
	} as const,
	PACKOUT_FACILITY: {
		ADD: {
			ALL: 'packoutfacility.add.all',
		} as const,
		EDIT: {
			ALL: 'packoutfacility.edit.all',
		} as const,
		VIEW: {
			ALL: 'packoutfacility.view.all',
			COMPANY: 'packoutfacility.view.company',
		} as const,
	} as const,
	PHYSICAL_ORDER: {
		APPROVE: {
			ALL: 'physicalorder.approve.all',
		} as const,
		EDIT: {
			COMPANY: 'physicalorder.edit.company',
		} as const,
		REVIEW: {
			ALL: 'physicalorder.review.all',
		} as const,
		VIEW: {
			ALL: 'physicalorder.view.all',
			COMPANY: 'physicalorder.view.company',
		} as const,
	} as const,
	PLATFORM: {
		VIEW: {
			ALL: 'platform.view.all',
		} as const,
	} as const,
	PRODUCT: {
		ADD: {
			COMPANY: 'product.add.company',
			FIRST: 'product.add.first',
		} as const,
		ASSET: {
			CODEINBOX: {
				ADD: {
					COMPANY: 'product.asset.codeinbox.add.company',
				} as const,
				APPROVE: {
					ALL: 'product.asset.codeinbox.approve.all',
				} as const,
				COMMENT: {
					ADD: {
						ALL: 'product.asset.codeinbox.comment.add.all',
						COMPANY: 'product.asset.codeinbox.comment.add.company',
					} as const,
				} as const,
				PROOF_REJECT: {
					ALL: 'product.asset.codeinbox.proof_reject.all',
				} as const,
				REVIEW: {
					ALL: 'product.asset.codeinbox.review.all',
				} as const,
				REVIEW_REJECT: {
					ALL: 'product.asset.codeinbox.review_reject.all',
				} as const,
				VIEW: {
					ALL: 'product.asset.codeinbox.view.all',
					COMPANY: 'product.asset.codeinbox.view.company',
				} as const,
			} as const,
			LABEL: {
				APPROVE: {
					COMPANY: 'product.asset.label.approve.company',
				} as const,
				REVIEW: {
					ALL: 'product.asset.label.review.all',
				} as const,
			} as const,
			MARKETING: {
				ADD: {
					ALL: 'product.asset.marketing.add.all',
					COMPANY: 'product.asset.marketing.add.company',
				} as const,
				APPROVE: {
					ALL: 'product.asset.marketing.approve.all',
				} as const,
				COMMENT: {
					ADD: {
						ALL: 'product.asset.marketing.comment.add.all',
						COMPANY: 'product.asset.marketing.comment.add.company',
					} as const,
				} as const,
				VIEW: {
					ALL: 'product.asset.marketing.view.all',
					COMPANY: 'product.asset.marketing.view.company',
				} as const,
			} as const,
			PACKAGING: {
				ADD: {
					COMPANY: 'product.asset.packaging.add.company',
				} as const,
				APPROVE: {
					ALL: 'product.asset.packaging.approve.all',
				} as const,
				COMMENT: {
					ADD: {
						ALL: 'product.asset.packaging.comment.add.all',
						COMPANY: 'product.asset.packaging.comment.add.company',
					} as const,
				} as const,
				VIEW: {
					ALL: 'product.asset.packaging.view.all',
					COMPANY: 'product.asset.packaging.view.company',
				} as const,
			} as const,
		} as const,
		COMPONENT: {
			DECLARED_PRICE: {
				REQUEST: {
					ADD: {
						COMPANY: 'product.component.declaredprice.request.add.company',
					} as const,
					APPROVE: {
						ALL: 'product.component.declaredprice.request.approve.all',
					} as const,
					EXTEND_LIMIT: {
						ALL: 'product.component.declaredprice.request.extend_limit.all',
					} as const,
					LIMIT: {
						EDIT: {
							ALL: 'product.component.declaredprice.request.limit.edit.all',
						} as const,
					} as const,
					RETRACT: {
						COMPANY: 'product.component.declaredprice.request.retract.company',
					} as const,
					REVIEW: {
						ALL: 'product.component.declaredprice.request.review.all',
					} as const,
					VIEW: {
						ALL: 'product.component.declaredprice.request.view.all',
						COMPANY: 'product.component.declaredprice.request.view.company',
					},
				} as const,
			} as const,
			PARENT: {
				EDIT: {
					ALL: 'product.component.parent.edit.all',
				},
			} as const,
			WHOLESALE_PRICE: {
				REQUEST: {
					ADD: {
						COMPANY: 'product.component.wholesaleprice.request.add.company',
					} as const,
					APPROVE: {
						ALL: 'product.component.wholesaleprice.request.approve.all',
					} as const,
					LIMIT: {
						EDIT: {
							ALL: 'product.component.wholesaleprice.request.limit.edit.all',
						} as const,
					} as const,
					RETRACT: {
						COMPANY: 'product.component.wholesaleprice.request.retract.company',
					} as const,
					REVIEW: {
						ALL: 'product.component.wholesaleprice.request.review.all',
					} as const,
					VIEW: {
						ALL: 'product.component.wholesaleprice.request.view.all',
						COMPANY: 'product.component.wholesaleprice.request.view.company',
					} as const,
				} as const,
			} as const,
			VIEW: {
				ALL: 'product.component.view.all',
				COMPANY: 'product.component.view.company',
			},
		} as const,
		DIGITAL_CATALOG: {
			VIEW: {
				ALL: 'product.digitalcatalog.view.all',
				COMPANY: 'product.digitalcatalog.view.company',
			} as const,
		} as const,
		EDIT: {
			GAME_CODE: 'product.edit.gamecode',
			PARTSKU: 'product.edit.partsku',
			STATUS: 'product.edit.status',
		} as const,
		LOTCHECK: {
			APPROVAL: 'product.lotcheck.approval',
			PRIORITY: 'product.lotcheck.priority',
			RESET: 'product.lotcheck.reset',
		} as const,
		REQUEST: {
			GAME_CODE: 'product.request.gamecode',
		} as const,
		VIEW: {
			ALL: {
				CONFIDENTIAL: 'product.view.all.confidential',
				FIRST: 'product.view.all.first',
				NCL: 'product.view.all.ncl',
				THIRD: 'product.view.all.third',
			} as const,
			COMPANY: 'product.view.company',
			DETAIL: {
				PROJECTIONS: 'product.view.detail.projections',
				SUBMISSIONS: 'product.view.detail.submissions',
			} as const,
			PRICE: {
				COMPANY: 'product.view.price.company',
				ALL: 'product.view.price.all',
			} as const,
		} as const,
		RIGHT_TRANSFER: {
			PROCESS: {
				ALL_OWNERSHIP: 'product.right_transfer.process.all.ownership',
				ALL_DISTRIBUTION: 'product.right_transfer.process.all.distribution',
			} as const,
			PROCESS_REJECT: {
				ALL_OWNERSHIP: 'product.right_transfer.process_reject.all.ownership',
				ALL_DISTRIBUTION: 'product.right_transfer.process_reject.all.distribution',
			} as const,
			RECEIVE: {
				COMPANY: 'product.right_transfer.receive.company',
			} as const,
			REQUEST: {
				COMPANY: 'product.right_transfer.request.company',
			} as const,
			RESCIND: {
				COMPANY: 'product.right_transfer.rescind.company',
			} as const,
			REVIEW: {
				ALL_OWNERSHIP: 'product.right_transfer.review.all.ownership',
				ALL_DISTRIBUTION: 'product.right_transfer.review.all.distribution',
			} as const,
			REVIEW_REJECT: {
				ALL_OWNERSHIP: 'product.right_transfer.review_reject.all.ownership',
				ALL_DISTRIBUTION: 'product.right_transfer.review_reject.all.distribution',
			} as const,
			REVOKE: {
				COMPANY: 'product.right_transfer.revoke.company',
			} as const,
			VIEW: {
				ALL_OWNERSHIP: 'product.right_transfer.view.all.ownership',
				ALL_DISTRIBUTION: 'product.right_transfer.view.all.distribution',
				COMPANY: 'product.right_transfer.view.company',
			} as const,
		} as const,
	} as const,
	PROJECTION: {
		EDIT: {
			COMPANY: 'projection.edit.company',
		} as const,
		NOTIFY: {
			ALL: 'projection.notify.all',
		} as const,
		VIEW: {
			ALL: 'projection.view.all',
			COMPANY: 'projection.view.company',
		} as const,
		WINDOW: {
			EDIT: 'projection.window.edit',
		} as const,
	} as const,
	PUBLISHER_MANAGEMENT: {
		VIEW: {
			ALL: 'publishermgmt.view.all',
		} as const,
	} as const,
	ROM_VERSION_FILE: {
		UPLOAD: {
			ALL: 'rom_version_file.upload.all',
		} as const,
		VIEW: {
			ALL: 'rom_version_file.view.all',
		},
	} as const,
	SUBMISSION: {
		ADD: {
			ALL: 'submission.add.all',
		} as const,
		COMMENT: {
			ADD: {
				ALL: 'submission.comment.add.all',
				COMPANY: 'submission.comment.add.company',
			} as const,
		} as const,
		EDIT: {
			ALL: 'submission.edit.all',
		} as const,
		LOTCHECK_QUEUE: {
			VIEW: {
				ALL: 'submission.lotcheck_queue.view.all',
			} as const,
		} as const,
		ROM: {
			APPROVAL: 'submission.rom.approval',
		},
	} as const,
	USER: {
		MARKETING_EVENTS_LEAD: {
			VIEW: {
				COMPANY: 'user.marketingeventslead.view.company',
			} as const,
		} as const,
		MARKETING_EVENTS_POWER_USER: {
			VIEW: {
				COMPANY: 'user.marketingeventspoweruser.view.all',
			} as const,
		} as const,
		MARKETING_EVENTS_SUPPORT: {
			VIEW: {
				COMPANY: 'user.marketingeventssupport.view.company',
			} as const,
		} as const,
		MARKETING_EVENTS_TEAM_MEMBER: {
			VIEW: {
				ALL: 'user.marketingeventsteammember.view.all',
			} as const,
		} as const,
		VIEW: {
			ALL: 'user.view.all',
			COMPANY: 'user.view.company',
		} as const,
	} as const,
	USER_MANAGEMENT: {
		ADD: {
			ALL: 'usermgmt.add.all',
			COMPANY: 'usermgmt.add.company',
			INTERNAL: 'usermgmt.add.internal',
		} as const,
		COMPANY_FILTER: {
			ALL: 'usermgmt.companyfilter.all',
		} as const,
		EDIT: {
			COMPANY: 'usermgmt.edit.company',
			INTERNAL: 'usermgmt.edit.internal',
		} as const,
		ROLE_FILTER: {
			ALL: 'usermgmt.rolefilter.all',
			EXTERNAL: 'usermgmt.rolefilter.external',
			INTERNAL: 'usermgmt.rolefilter.internal',
		} as const,
		STATUS: {
			EDIT: {
				ALL: 'usermgmt.status.edit.all',
			} as const,
		} as const,
		VIEW: {
			ALL: 'usermgmt.view.all',
			COMPANY: 'usermgmt.view.company',
		} as const,
	} as const,
} as const;

export const accessTypeConst = {
	ADMIN: 'ADMIN',
	ALL: 'ALL',
	COMPANY: 'COMPANY',
	COORDINATOR: 'COORDINATOR',
	INTERNAL: 'INTERNAL',
	LEAD: 'LEAD',
	MANAGER: 'MANAGER',
	SUPPORT: 'SUPPORT'
} as const;
